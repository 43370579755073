import { api_get_filter_count_filter } from '../../../api/endpoint/filter/api_get_filter_count_filters';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjCountFilters } from '../../../api/object/filter/ApiObjCountFilters';
import { ApiObjFilter } from '../../../api/object/filter/ApiObjFilter';
import { UtilArray } from '../../../util/code/UtilArray';
import { MapAreaCircle } from '../../map/MapAreaCircle';
import { MapAreaPolygon } from '../../map/MapAreaPolygon';
import { CountFilterDataItem } from './CountFilterDataItem';

export type CountFilterParams = {
    nix_option_id: number,
    source_id: number,
    filter_ids_selected: Array<number>,
    filter_ids_to_check: Array<number>,
    map_areas: Array<MapAreaCircle|MapAreaPolygon>,
}

/**
 * Manages count calls (order). An important reason is preventing spamming when
 * the user changes the order quickly.
 */
export class CountFilterManager {
    
    private baseData: ApiObjBaseData;
    private callbackNewData: (newData: Array<CountFilterDataItem>) => void;
    private lastParams: undefined|CountFilterParams;
    private countFilterData: Array<CountFilterDataItem> = [];
    private requestCounter = 0;              // Used to determine if a scheduled request is the latest.
    private timestampLastParamChange = 0;    // Used to determine whether to make request immediately.

    // ================================================================
    // === Create
    // ================================================================

    constructor(baseData: ApiObjBaseData, callbackNewData: (newData: Array<CountFilterDataItem>) => void) {
        this.baseData = baseData;
        this.callbackNewData = callbackNewData;
    }

    // ================================================================
    // === Actions
    // ================================================================

    public actionNewParams = (params: CountFilterParams) => {
        this.lastParams = params;
        this.tryUpdateFilterCountItems();
        this.tryScheduleFilterCount()
    }

    private makeCallback = () => {
        const newData = [];
        for (const item of this.countFilterData) {
            newData.push(CountFilterDataItem.createClone(item));
        }
        this.callbackNewData(newData);
    }

    // ================================================================
    // === Helpers
    // ================================================================

    private tryUpdateFilterCountItems = () : void => {

        if (this.lastParams === undefined) {
            return;
        }

        if (this.baseData === undefined) {
            return;
        }

        const diff = this.getFilterCountDifference();
        if (
            diff.filter_ids_to_add.length === 0
            && diff.filter_ids_to_invalidate.length === 0
            && diff.filter_ids_to_remove.length === 0
        ) {
            return;
        }

        let newArray : Array<CountFilterDataItem>= [];
        for (const item of this.countFilterData) {
            newArray.push(CountFilterDataItem.createClone(item));
        }

        for (const filterId of diff.filter_ids_to_remove) {
            newArray = newArray.filter((item : CountFilterDataItem) => {
                return item.filter_id !== filterId
            });
        }

        const selectedFilters : Array<ApiObjFilter> = [];
        for (const filterId of this.lastParams.filter_ids_selected) {
            const filter = this.baseData.filter_map.get(filterId);
            if (filter === undefined) {
                console.error("Filter not found: filter_id: "+filterId);
                continue;
            }
            selectedFilters.push(filter);
        }

        let mapAreaIds = [];
        for (const mapArea of this.lastParams.map_areas) {
            mapAreaIds.push(mapArea.id);
        }
        mapAreaIds = UtilArray.createUniqueSortedIntArray(mapAreaIds);

        for (const filterId of diff.filter_ids_to_invalidate) {
            for (const countItem of newArray) {
                if (countItem.filter_id === filterId) {
                    countItem.invalidateCount();
                    countItem.setParams(this.lastParams.nix_option_id, this.lastParams.source_id, selectedFilters, mapAreaIds);
                }
            }
        }
        for (const filterId of diff.filter_ids_to_add) {
            const filter = this.baseData.filter_map.get(filterId);
            if (filter === undefined) {
                console.error("Filter not found: filter_id: "+filterId);
                continue;
            }
            const newItem = CountFilterDataItem.createNew(filter, this.lastParams.nix_option_id, this.lastParams.source_id);
            newItem.setParams(this.lastParams.nix_option_id, this.lastParams.source_id, selectedFilters, mapAreaIds);
            newArray.push(newItem);
        }

        this.countFilterData = newArray;

        this.makeCallback();
    }

    private tryScheduleFilterCount = () : void => {

        let hasWaitingItems = false;
        for (const countItem of this.countFilterData) {
            if (countItem.state === CountFilterDataItem.STATE_WAITING) {
                hasWaitingItems = true;
                break;
            }
        }

        if (!hasWaitingItems) {
            return; // Change did not affect counter => nothing to update.
        }

        this.requestCounter ++;
        const counterValue = this.requestCounter;

        const now = Date.now();
        const millisSinceLastChange = now - this.timestampLastParamChange;
        this.timestampLastParamChange = now;

        if (millisSinceLastChange > 5000) {
            // A long time (5 s) since last count. Run immediately.
            this.tryFetchFilterCount(counterValue);
        } else {
            // Not a long time ago. Delay count, user might update params soon.
            setTimeout(() => {this.tryFetchFilterCount(counterValue)}, 1200);
        }
    }

    private tryFetchFilterCount = (counterValue: number) => {

        if (this.lastParams === undefined) {
            return;
        }

        if (counterValue != this.requestCounter) {
            return; // Another fetch is scheduled after this. Let that run instead.
        }

        const filterIdsToCheck = [];
        for (const countItem of this.countFilterData) {
            if (countItem.state == CountFilterDataItem.STATE_WAITING) {
                countItem.state = CountFilterDataItem.STATE_REQUEST_SENT;
                filterIdsToCheck.push(countItem.filter_id);
            }
        }

        if (filterIdsToCheck.length === 0) {
            return;
        }

        api_get_filter_count_filter(
            this.lastParams.filter_ids_selected, 
            filterIdsToCheck,
            this.lastParams.nix_option_id,
            this.lastParams.source_id,
            this.lastParams.map_areas)

            .then((result) => {
                this.handleResultFilterCount(result);

            }).catch((err) => {
                // TODO ERIK hantera fel vid count. Sätt loading items till error kanske?? Nytt state.
                console.error("Could not fetch filter count");
                throw err;
            });
    }

    private handleResultFilterCount = (result: ApiObjCountFilters) : void => {

        if (this.baseData === undefined) {
            return;
        }

        const selectedFilters = [];
        for (const filterId of result.param_selected_filter_ids) {
            const filter = this.baseData.filter_map.get(filterId);
            if (filter === undefined) {
                console.error("Filter not found. Filter id: "+filterId);
                continue;
            }
            selectedFilters.push(filter);
        }

        for (const resultItem of result.result) {

            let match: CountFilterDataItem|undefined = undefined;

            for (const countItem of this.countFilterData) {
                if (countItem.filter_id === resultItem.filter_id) {
                    match = countItem;
                    break;
                }
            }

            if (
                match !== undefined
                && match.state !== CountFilterDataItem.STATE_READY
                && match.isValidForParams(result.param_nix_option_id, result.param_source_id, selectedFilters, result.param_map_area_ids)) {
                    match.setReady(resultItem.count);
            }
        }

        this.makeCallback();
    }

    private getFilterCountDifference = (): {
        filter_ids_to_add: Array<number>,
        filter_ids_to_invalidate: Array<number>,
        filter_ids_to_remove: Array<number>
    } => {

        if (this.baseData === undefined || this.lastParams === undefined) {
            return {
                filter_ids_to_add: [],
                filter_ids_to_invalidate: [],
                filter_ids_to_remove: []
            }
        }

        const filterMap = this.baseData.filter_map;
        const filtersSelected: Array<ApiObjFilter> = [];
        for (const filterId of this.lastParams.filter_ids_selected) {
            const tempFilter = filterMap.get(filterId);
            if (tempFilter !== undefined) {
                filtersSelected.push(tempFilter);
            }
        }

        let mapAreaIds = [];
        for (const mapArea of this.lastParams.map_areas) {
            mapAreaIds.push(mapArea.id);
        }
        mapAreaIds = UtilArray.createUniqueSortedIntArray(mapAreaIds);

        const currentVisibleFiltersIds = this.lastParams.filter_ids_to_check;

        const filterIdsToAdd: Array<number> = [];          // Not present in, add first.
        const filterIdsToInvalidate: Array<number> = [];   // Count is not based on current params.
        const filterIdsToRemove: Array<number> = [];       // Count is no longer visible and should be discarded.

        for (const countItem of this.countFilterData) {

            if (!currentVisibleFiltersIds.includes(countItem.filter_id)) {
                filterIdsToRemove.push(countItem.filter_id);
                
            } else if (!countItem.isValidForParams(this.lastParams.nix_option_id, this.lastParams.source_id, filtersSelected, mapAreaIds)) {
                filterIdsToInvalidate.push(countItem.filter_id);
                continue;
            }
        }

        const countItemIds = [];
        for (const countItem of this.countFilterData) {
            countItemIds.push(countItem.filter_id);
        }
        for (const filterId of currentVisibleFiltersIds) {
            if (!countItemIds.includes(filterId)) {
                const filter = this.baseData.filter_map.get(filterId);
                if (filter !== undefined && filter.is_selectable) { // TODO ERIK lägg till stöd för premium här.
                    filterIdsToAdd.push(filterId);
                }
            }
        }

        return {
            filter_ids_to_add: filterIdsToAdd,
            filter_ids_to_invalidate: filterIdsToInvalidate,
            filter_ids_to_remove: filterIdsToRemove
        }
    }
}