import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export const api_admin_user_get_export_fields = (userId: number) : Promise<Array<number>> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/admin/user/'+userId+'/export_field';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            const result = [];
            for (const item of resp.data) {
                result.push(parseInt(item));
            }
            resolve(result);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}