import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';
import { ApiObjAdminGlobalPrice } from '../../../object/admin/ApiObjAdminGlobalPrice';

export const api_admin_get_global_prices = () : Promise<Array<ApiObjAdminGlobalPrice>> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/admin/global_price';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            const result = [];
            for (const item of resp.data) {
                result.push(new ApiObjAdminGlobalPrice(item));
            }
            resolve(result);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}