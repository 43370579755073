import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';

export const api_admin_get_number_blacklist_entry = (number: string) : Promise<undefined|string> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/admin/number_blacklist?phone_number='+number;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            if (resp.data == undefined || resp.data == '') {
                return resolve(undefined);
            }
            return resolve(resp.data.phone_number);

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}