import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Chip, CircularProgress, Icon, MenuItem, Select, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { ApiObjPopulateFile } from '../../../api/object/populate_file/ApiObjPopulateFile';
import { api_populate_file_order_get_step_2 } from '../../../api/endpoint/populate_file/api_populate_file_order_get_step_2';
import { ApiObjPopulateFileOrderStep2 } from '../../../api/object/populate_file/ApiObjPopulateFileOrderStep2';
import { api_populate_file_order_post_step_2 } from '../../../api/endpoint/populate_file/api_populate_file_order_post_step_2';
import { StaticPopulateFileDataOption } from '../../../api/constants/populate_file/StaticPopulateFileDataOption';
import { StaticPopulateFileMatchType } from '../../../api/constants/populate_file/StaticPopulateFileMatchType';
import { ApiObjPriceObjectType } from '../../../api/object/price/ApiObjPriceObjectType';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    populateFile: ApiObjPopulateFile,
    setEstimatePrice: Dispatch<SetStateAction<number>>
    parentSetPopulateFile: (populateFile: ApiObjPopulateFile) => void
}

export const OrderStep2Content: FunctionComponent <Props> = ({
    baseData,
    userData,
    populateFile,
    setEstimatePrice,
    parentSetPopulateFile
}) => {

    const [isSaving, setIsSaving] = useState <boolean> (false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [orderStep2Obj, setOrderStep2Obj] = useState <undefined|ApiObjPopulateFileOrderStep2> (undefined);

    const [formDataOptions, setFormDataOptions] = useState <Map<number, number>> (new Map());
    const [formLowIncome, setFormLowIncome] = useState <number> (ApiObjPopulateFile.CLEAN_SETTING_OFF);
    const [formNix, setFormNix] = useState <number> (ApiObjPopulateFile.CLEAN_SETTING_OFF);
    const [formHlr, setFormHlr] = useState <number> (ApiObjPopulateFile.CLEAN_SETTING_OFF);

    const navigate = useNavigate();

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if(!orderStep2Obj) return
        let estimation = orderStep2Obj.price_estimate[0].price_total
        formDataOptions.forEach((item) => {
            orderStep2Obj.price_estimate.forEach((price) => {
                if(price.price_object.object_id === item && price.price_object.object_type_id === ApiObjPriceObjectType.ID_POPULATE_FILE_NEW_DATA){
                    estimation += price.price_total
                }
            })
        })
        if(formLowIncome) estimation += orderStep2Obj.price_estimate[8].price_total
        if(formNix) estimation += orderStep2Obj.price_estimate[9].price_total
        if(formHlr) estimation += orderStep2Obj.price_estimate[10].price_total
        setEstimatePrice(estimation)
    }, [orderStep2Obj, formLowIncome, formNix, formHlr, formDataOptions])
    
    useEffect(() => {
    
        const mapDataOptions = new Map();
        for (const item of populateFile.params.data_option_ids) {
            mapDataOptions.set(item, item);
        }
        setFormDataOptions(mapDataOptions);

        setFormLowIncome(populateFile.params.clean_low_income);
        setFormNix(populateFile.params.clean_nix);
        setFormHlr(populateFile.params.clean_hlr);

        api_populate_file_order_get_step_2(populateFile.id)
            .then((result) => {
                setOrderStep2Obj(result);
            }).catch((err) => {
                // TODO feedback till användaren.
            });
    }, [populateFile.id]);

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionNext = () => {
        if (isSaving) {
            return;
        }
        setIsSaving(true);
        
        const dataOptions = Array.from(formDataOptions, ([key, value]) => (key));

        api_populate_file_order_post_step_2(populateFile.id, dataOptions, formLowIncome, formNix, formHlr)
            .then((result) => {
                setIsSaving(false);
                parentSetPopulateFile(result);
                navigate('/populate_file/'+populateFile.id+'/order_step_3');
            });
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderDataOptions = () : undefined|JSX.Element => {
        const checkBoxes = [];
        for (const item of StaticPopulateFileDataOption.ALL) {
            const priceObject = userData.api_data?.getPriceObjectFromType(ApiObjPriceObjectType.ID_POPULATE_FILE_NEW_DATA, item.id);
            checkBoxes.push(
                <Box
                    key={item.id}
                    display={'flex'}
                    flexDirection={'row'}
                    alignContent={'center'}
                    justifyContent={"space-between"}
                    marginLeft={"-0.6rem"}
                    width="36rem"
                    >
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Checkbox
                                checked={formDataOptions.has(item.id)}
                                disabled={isSaving}
                                color={'secondary'}
                                onChange={(event) => {
                                    const isChecked = event.target.checked;
                                    const newMap = new Map(formDataOptions);
                                    if (isChecked) {
                                        newMap.set(item.id, item.id);
                                    } else {
                                        newMap.delete(item.id);
                                    }
                                    setFormDataOptions(newMap);
                                }}/>
                            <Box>
                                {item.name}
                            </Box>
                        </Box>
                        {userData.isLoggedIn() && userData.api_data !== undefined && priceObject !== undefined && priceObject.getPriceFloat() > 0 &&                        
                            <Box marginRight={'8px'} position={"relative"} style={{height: "100%"}}>
                                <Chip style={{ position: "relative", zIndex: "50" }} label={priceObject.getPriceStringFull()} variant="filled" />
                                {
                                    formDataOptions.has(item.id) && (
                                        <Box position="absolute" top={0} left={0} right={0} bottom={0} bgcolor={'#faa63c'} borderRadius={"16px"}></Box>
                                    )
                                }
                            </Box>
                        }
                </Box>
            );
        }

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <Box marginBottom={"0.5rem"}><b>Data att uppdatera och lägga till</b></Box>
                {checkBoxes}
            </Box>
        );
    }

    const renderCleanOptions = () : undefined|JSX.Element => {
        const priceObjectLowIncome = userData.api_data?.getPriceObjectFromType(ApiObjPriceObjectType.ID_POPULATE_FILE_CLEAN, 1);
        const priceObjectNix = userData.api_data?.getPriceObjectFromType(ApiObjPriceObjectType.ID_POPULATE_FILE_CLEAN, 2);
        const priceObjectHlr = userData.api_data?.getPriceObjectFromType(ApiObjPriceObjectType.ID_POPULATE_FILE_CLEAN, 3);
        return (
            <Box display={'flex'} flexDirection={'column'} width={"35rem"}>
                <Box marginBottom={"1rem"} display={"flex"} alignItems={"center"}>
                    <Box><b>Tvätt</b></Box>
                    <div style={{ marginLeft: "0.25rem", paddingTop: "0.45rem" }}>
                        <Typography
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        >
                            <InfoOutlinedIcon fontSize='small'/>
                        </Typography>
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Typography sx={{ p: 1 }} maxWidth={"15rem"}>
                                <b>Betalningsförmåga:</b>Kontrollera om kunderna har möjlighet att betala för nya varor och tjänster
                                <br/>
                                <b>Nix:</b> Kolla om numren i eran fil är NIXade
                                <br/>
                                <b>Pinga nummer:</b> Kontrollera om telefonnumren i er fil är aktivt och ansluten till en operatör
                            </Typography>
                        </Popover>
                    </div>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Select style={{marginBottom: "0.75rem", width: "65%"}} value={formLowIncome} onChange={(e) => {
                    if (typeof e.target.value === 'string') {
                        setFormLowIncome(parseInt(e.target.value));
                        } else {
                            setFormLowIncome(e.target.value);
                        }
                    }}>
                        <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_MARK} value={ApiObjPopulateFile.CLEAN_SETTING_MARK}>Betalningsförmåga (markera)</MenuItem>
                        <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_DELETE} value={ApiObjPopulateFile.CLEAN_SETTING_DELETE}>Betalningsförmåga (ta bort)</MenuItem>
                        <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_OFF} value={ApiObjPopulateFile.CLEAN_SETTING_OFF}>Betalningsförmåga (ignorera)</MenuItem>
                    </Select>
                    {userData.isLoggedIn() && userData.api_data !== undefined && priceObjectLowIncome !== undefined && priceObjectLowIncome.getPriceFloat() > 0 &&                        
                        <Box marginRight={'8px'} position={"relative"}>
                            <Chip style={{ position: "relative", zIndex: "50" }} label={priceObjectLowIncome.getPriceStringFull()} variant="filled" />
                            {
                                !!formLowIncome && (
                                    <Box position="absolute" top={0} left={0} right={0} bottom={0} bgcolor={'#faa63c'} borderRadius={"16px"}></Box>
                                )
                            }
                        </Box>
                    }
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Select style={{marginBottom: "0.75rem", width: "65%"}} value={formNix} onChange={(e) => {
                        if (typeof e.target.value === 'string') {
                            setFormNix(parseInt(e.target.value));
                        } else {
                            setFormNix(e.target.value);
                        }
                    }}>
                            <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_MARK} value={ApiObjPopulateFile.CLEAN_SETTING_MARK}>Nix (markera)</MenuItem>
                            <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_DELETE} value={ApiObjPopulateFile.CLEAN_SETTING_DELETE}>Nix (ta bort)</MenuItem>
                            <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_OFF} value={ApiObjPopulateFile.CLEAN_SETTING_OFF}>Nix (ignorera)</MenuItem>
                    </Select>
                    {userData.isLoggedIn() && userData.api_data !== undefined && priceObjectNix !== undefined && priceObjectNix.getPriceFloat() > 0 &&                        
                        <Box marginRight={'8px'} position={"relative"}>
                            <Chip style={{ position: "relative", zIndex: "50" }} label={priceObjectNix.getPriceStringFull()} variant="filled" />
                            {
                                !!formNix && (
                                    <Box position="absolute" top={0} left={0} right={0} bottom={0} bgcolor={'#faa63c'} borderRadius={"16px"}></Box>
                                )
                            }
                        </Box>
                    }
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Select style={{marginBottom: "0.75rem", width: "65%"}} value={formHlr} onChange={(e) => {
                        if (typeof e.target.value === 'string') {
                            setFormHlr(parseInt(e.target.value));
                        } else {
                            setFormHlr(e.target.value);
                        }
                    }}>
                            <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_MARK} value={ApiObjPopulateFile.CLEAN_SETTING_MARK}>Pinga nummer (markera)</MenuItem>
                            <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_DELETE} value={ApiObjPopulateFile.CLEAN_SETTING_DELETE}>Pinga nummer (ta bort)</MenuItem>
                            <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_OFF} value={ApiObjPopulateFile.CLEAN_SETTING_OFF}>Pinga nummer (ignorera)</MenuItem>
                    </Select>
                    {userData.isLoggedIn() && userData.api_data !== undefined && priceObjectHlr !== undefined && priceObjectHlr.getPriceFloat() > 0 &&                       
                     <Box marginRight={'8px'} position={"relative"}>
                        <Chip style={{ position: "relative", zIndex: "50" }} label={priceObjectHlr.getPriceStringFull()} variant="filled" />
                        {
                            !!formHlr && (
                                <Box position="absolute" top={0} left={0} right={0} bottom={0} bgcolor={'#faa63c'} borderRadius={"16px"}></Box>
                            )
                        }
                    </Box>}
                </Box>
            </Box>
        );
    }

    const renderButtonNext = () : JSX.Element => {
        return (
            <Button
                variant='contained'
                disabled={isSaving}
                onClick={actionNext}>
                    Bekräfta
            </Button>
        );
    }

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <>
            {
                isSaving ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"50vh"}><CircularProgress/></Box>
                ) : (
                    <Box mt={2} display={'flex'} flexDirection={'column'}>
                        <Box marginTop={'8px'}>
                            {renderDataOptions()}
                        </Box>
                        <Box marginTop={'28px'}>
                            {renderCleanOptions()}
                        </Box>
                        <Box mt={2} style={{overflowX: 'auto'}}>
                            {renderButtonNext()}
                        </Box>
                    </Box>
                )
            }
        </>
    );
};
