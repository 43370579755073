import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjAdAccount } from '../../object/facebook/ApiObjAdAccount';

export type ApiParamsCreateAdAccount = {
    name: string,
    remote_id: string,
}

export const api_create_ad_account = (data: ApiParamsCreateAdAccount) : Promise<ApiObjAdAccount> => {
    return new Promise<ApiObjAdAccount> ((resolve, reject) => {
        const url = API_URL+'/facebook/ad_account';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        Axios.post(
            url,
            data,
            config
        )
        .then((resp) => {
            return resolve(new ApiObjAdAccount(resp.data));

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}

