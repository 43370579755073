import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { MapAreaCircle } from '../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../data_layer/map/MapAreaPolygon';

export interface CreateExportParams {
    nixOptionId: number,
    sourceId: number,
    filterIds: Array<number>,
    mapAreas: Array<MapAreaCircle|MapAreaPolygon>,
    stopfileIds: Array<number>,
    householdOptionId: number,
    leadsCount: number,
    sizeLimit: number,
    exportFieldIds: Array<number>,
    name: string,
    paymentMethod: number
}

export const api_create_export = (params: CreateExportParams) : Promise<any> => {

    return new Promise((resolve, reject) => {
        const url = API_URL+'/export/create';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        const payloadMapAreas = [];
        for (const mapArea of params.mapAreas) {
            if (mapArea instanceof MapAreaCircle) {
                payloadMapAreas.push({
                    type: 'circle',
                    center_lat: mapArea.centerPoint.latitude,
                    center_long: mapArea.centerPoint.longitude,
                    radius: mapArea.radius
                });

            } else if (mapArea instanceof MapAreaPolygon) {
                const tempPoints = [];
                for (const point of mapArea.points) {
                    tempPoints.push({
                        lat: point.latitude,
                        long: point.longitude,
                    });
                }
                payloadMapAreas.push({
                    type: 'polygon',
                    points: tempPoints
                });
            } else {
                throw new Error("Unexpected map area instance type");
            }
        }

        const data = {
            nix_option_id: params.nixOptionId,
            source_id: params.sourceId,
            filter_ids: params.filterIds,
            map_areas: payloadMapAreas,
            export_field_ids: params.exportFieldIds,
            stopfile_ids:  params.stopfileIds,
            household_option_id: params.householdOptionId,
            leads_count: params.leadsCount,
            size_limit: params.sizeLimit,
            name: params.name,
            payment_method: params.paymentMethod,
        }
        Axios.post(
            url,
            data,
            config
        )
        .then((resp) => {
            resolve(resp.data);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}