import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_order_post_step_4 = (populateFileId: number, paymentMethod: number, priceTotalCheck: number) : Promise<{
    payment_method: number,
    populate_file: ApiObjPopulateFile,
    stripe_public_key: string|undefined,
    stripe_client_secret: string|undefined,
}> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/populate_file/'+populateFileId+'/order_step_4';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        const body = {
            payment_method: paymentMethod,
            price_total_check: priceTotalCheck
        }
        Axios.post(
            url,
            body,
            config
        )
        .then((resp) => {
            resolve({
                payment_method: parseInt(resp.data.payment_method),
                populate_file: new ApiObjPopulateFile(resp.data.populate_file),
                stripe_public_key: resp.data.stripe_public_key,
                stripe_client_secret: resp.data.stripe_client_secret,
            });
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}