import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjApiKey } from '../../object/api_key/ApiObjApiKey';

export const api_get_api_keys = () : Promise<Array<ApiObjApiKey>> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/api_key';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            const ret = [];
            for (const item of resp.data) {
                ret.push(new ApiObjApiKey(item));
            }
            return resolve(ret);

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}