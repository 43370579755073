import { Box, Button, Checkbox } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { api_admin_user_get_filters } from '../../../api/endpoint/admin/user/filter/api_admin_user_get_filters';
import { api_admin_user_save_filters } from '../../../api/endpoint/admin/user/filter/api_admin_user_save_filters';
import { ApiObjFilter } from '../../../api/object/filter/ApiObjFilter';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData,
    otherUserId: number,
}

export const AdminUserTabFilters: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    otherUserId
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [mapUserFilters, setMapUserFilters] = useState <Map<number, number>> (new Map());
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [isSaving, setIsSaving] = useState <boolean> (false);
    const isActive = !isLoading && !isSaving;

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {

        setIsLoading(true);
        api_admin_user_get_filters(otherUserId)
            .then((result) => {
                const newMap = new Map();
                for (const item of result) {
                    newMap.set(item, item);
                }
                setMapUserFilters(newMap);
                setIsLoading(false);
            });

    }, [otherUserId]);

    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionSaveFilters = () => {

        setIsSaving(true);

        const filterIds : Array<number> = [];
        mapUserFilters.forEach((value: number, key: number) => {
            filterIds.push(key);
        });

        api_admin_user_save_filters(otherUserId, filterIds)
            .then((result) => {
                const newMap = new Map();
                for (const item of result) {
                    newMap.set(item, item);
                }
                setMapUserFilters(newMap);
                setIsSaving(false);

            }).catch((err) => {
                console.error("Could not save filters");
                console.error(err);
                // Skip to set isSaving to fails to make error obvious.
            });
    }

    const actionOnCheckboxChange = (filterId: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const newMap = new Map(mapUserFilters);
        if (isChecked) {
            newMap.set(filterId, filterId);
        } else {
            newMap.delete(filterId);
        }
        setMapUserFilters(newMap);
    }

    // ======================================================================
    // === Helpers
    // ======================================================================

    const getPremimFilters = (filter: ApiObjFilter, result: Array<ApiObjFilter>) => {
        if (filter.is_premium) {
            result.push(filter);
        }
        for (const child of filter.children) {
            getPremimFilters(child, result);
        }
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderList = () : JSX.Element => {

        const premiumFilters: Array<ApiObjFilter> = [];
        for (const filter of baseData.filter_tree) {
            getPremimFilters(filter, premiumFilters);
        }

        const elements : Array<JSX.Element> = [];
        for (const item of premiumFilters) {
            if (item.is_premium) {
                elements.push(renderItem(item));
            }
        }

        return (
            <Box>
                {elements}
            </Box>
        );
    }

    const renderItem = (filter: ApiObjFilter) : JSX.Element => {

        const isSelected = mapUserFilters.has(filter.id);

        return (
            <Box
                key={filter.id}
                display={'flex'}
                flexDirection={'row'}
                alignContent={'center'}>
                    <Box>
                        <Checkbox
                            checked={isSelected}
                            disabled={!isActive}
                            color={'secondary'}
                            onClick={(event) => { event.stopPropagation(); }}
                            onChange={(event) => { actionOnCheckboxChange(filter.id, event) }}/>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        {filter.name}
                    </Box>
            </Box>
        );
    }

    if (isLoading) {
        return (<Box padding={'14px'}>Laddar...</Box>);
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            {renderList()}
            <Box paddingTop={'15px'}>
                <Button variant='contained' color='secondary' onClick={actionSaveFilters} disabled={!isActive}>
                    Spara
                </Button>
            </Box>
        </Box>
    );
}
