import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjBaseData } from '../../object/base_data/ApiObjBaseData';

export const api_get_base_data = () : Promise<ApiObjBaseData> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/base_data';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            resolve(new ApiObjBaseData(resp.data));

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}