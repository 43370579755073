import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { FilterTreeMiniItem } from './FilterTreeMiniItem';
import { ApiObjFilter } from '../../api/object/filter/ApiObjFilter';
import { UtilArray } from '../../util/code/UtilArray';

export class FilterTreeMini {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    private creation_filter_ids : Array<number>;
    public root_items : Array<FilterTreeMiniItem>;

    // ==========================================================================
    // === Create
    // ==========================================================================

    constructor(filterIds: Array<number>, baseData: ApiObjBaseData) {

        this.creation_filter_ids = [...filterIds];

        // Step 1 get all ids to include (leaf to root)
        const idsMap : Map<number, number> = new Map();
        for (const id of filterIds) {
            FilterTreeMini.mapAllIds(id, idsMap, baseData);
        }

        // Step 2 include relevant filters (root to leaf)
        this.root_items = [];
        for (const filter of baseData.filter_tree) {
            if (idsMap.has(filter.id)) {
                this.root_items.push(FilterTreeMini.createFilterTreeItem(filter, idsMap));
            }
        }
    }

    private static mapAllIds = (filterId: number, idsMap: Map<number, number>, baseData: ApiObjBaseData) => {
        idsMap.set(filterId, 1);
        const parent = baseData.getFilterParent(filterId);
        if (parent !== undefined) {
            this.mapAllIds(parent.id, idsMap, baseData);
        }
    }

    private static createFilterTreeItem = (filter: ApiObjFilter, idsMap: Map<number, number>) : FilterTreeMiniItem => {
        const children: Array<FilterTreeMiniItem> = [];
        for (const child of filter.children) {
            if (idsMap.has(child.id)) {
                children.push(this.createFilterTreeItem(child, idsMap));
            }
        }
        return new FilterTreeMiniItem(filter, children);
    }

    // ==========================================================================
    // === Create
    // ==========================================================================

    public isEqualToFilterIds = (filterIds: Array<number>) : boolean => {
        return UtilArray.arrayEquals(this.creation_filter_ids, filterIds);
    }
}