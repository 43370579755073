import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfile } from '../../object/stopfile/ApiObjStopfile';

export const api_create_stopfile = (file: File) : Promise<ApiObjStopfile> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/stopfile/create';

        const fd = new FormData();
        fd.append('file', new Blob([file], { type: 'text/csv' }), file.name);
        
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true,
            headers: {
                accept: 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': 'multipart/form-data;'
                //'Content-Type': `multipart/form-data; boundary=${fd._boundary}`
            }
        }
        Axios.post(
            url,
            fd,
            config
        )
        .then((resp) => {
            resolve(new ApiObjStopfile(resp.data));
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}