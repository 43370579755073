import { Box, Button, Checkbox, Chip, CircularProgress, IconButton, TextField, } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../data_layer/user/UserData';
import { ApiObjFilter } from '../../api/object/filter/ApiObjFilter';
import { CountFilterDataItem } from '../../data_layer/count/filter/CountFilterDataItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { util_strings_format_with_spaces } from '../../util/strings/util_strings';
import { ApiObjPriceObjectType } from '../../api/object/price/ApiObjPriceObjectType';
import { UtilArray } from '../../util/code/UtilArray';
import IconClear from '@mui/icons-material/Clear';

// ===========================================================
// === Local Types
// ===========================================================

type SearchResult = {
    filter: ApiObjFilter
    is_direct_match: boolean
    children_match_count: number
}

type SelectionState = {
    is_selected: boolean,
    selected_child_count: number
}

// ===========================================================
// === Props
// ===========================================================

type Props = {
    userData: UserData,
    baseData: ApiObjBaseData,
    selectedFilterIds: Array<number>,
    visibleFilterIds: Array<number>,
    filterCounts: Array<CountFilterDataItem>,
    nixOptionId: number,
    onSelectedFilterIdsChanged: ((selectedFilterIds: Array<number>) => void),
    onVisibleFilterIdsChanged: ((visibleFilterIds: Array<number>) => void),
    disablePrices: boolean,
    disabled: boolean,
}

export const FilterTree: FunctionComponent<Props> = ({
    userData,
    baseData,
    selectedFilterIds,
    visibleFilterIds,
    filterCounts,
    nixOptionId,
    onSelectedFilterIdsChanged,
    onVisibleFilterIdsChanged,
    disablePrices,
    disabled,
}) => {

    // ===========================================================
    // === Settings
    // ===========================================================

    const FILTERS_WITH_SEARCH_FIELD = [251, 252, 1302, 1303];

    // ===========================================================
    // === State
    // ===========================================================

    const [mouseOverFilterId, setMouseOverFilterId] = useState <number> (0);
    const [expandedFilterIds, setExpandedFilterIds] = useState <Array<number>> ([]);
    const [searchText, setSearchText] = useState <string> ('');
    const [searchCurrentFilterId, setSearchCurrentFilterId] = useState <number> (0);
    const [searchResultMap, setSearchResultMap] = useState <Map<number, SearchResult>> (new Map());
    const [selectionStateMap, setSelectionStateMap] = useState <Map<number, SelectionState>> (new Map());

    // ===========================================================
    // === Effect, selection state
    // ===========================================================

    useEffect(() => {
        calculateSelectionStateMap();
      }, [selectedFilterIds]);

    const calculateSelectionStateMap = () => {
        const newSelectionStateMap : Map<number, SelectionState> = new Map();

        // Updates all parents of all selected filters.
        for (const filterId of selectedFilterIds) {

            const filter = baseData.filter_map.get(filterId);
            if (filter === undefined) {
                continue;
            }

            const filtersToRoot = [
                filter,
                ...baseData.getFilterAllParents(filterId)
            ];

            for (const parentFilter of filtersToRoot) {

                if (newSelectionStateMap.has(parentFilter.id)) {
                    // Skip parents already calculated.
                    continue;
                }

                const allChildren : Array<ApiObjFilter>= [];
                baseData.getFilterAllChildrenFromFilter(parentFilter, allChildren);

                let selectedChildCount = 0;
                for (const child of allChildren) {
                    if (selectedFilterIds.includes(child.id)) {
                        selectedChildCount ++;
                    }
                }
                const isSelected = selectedFilterIds.includes(parentFilter.id);
                
                newSelectionStateMap.set(
                    parentFilter.id,
                    {
                        is_selected: isSelected,
                        selected_child_count: selectedChildCount
                    }
                );
            }
        }
        setSelectionStateMap(newSelectionStateMap);
    }

    // ===========================================================
    // === Actions, highlight
    // ===========================================================

    const actionItemBodyMouseEnter = (filterId: number) => {
        setMouseOverFilterId(filterId);
    }

    const actionItemBodyMouseExit = (filterId: number) => {
        setMouseOverFilterId(0);
    }

    // ===========================================================
    // === Actions, Expand / Collapse
    // ===========================================================

    const actionItemBodyClicked = (filterId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const filter = baseData.filter_map.get(filterId);
        if (filter === undefined) {
            return;
        }
        if (filter.children.length > 0) {
            const isExpanded = isFilterExpanded(filterId);
            if (isExpanded) {
                subActionCollapse(filterId);
            } else {
                subActionExpand(filterId);
            }

        } else {
            const isSelected = isFilterSelected(filterId);
            if (isSelected) {
                subActionUnselectFilter(filterId);
            } else {
                subActionSelectFilter(filterId);
            }
        }
    }

    const actionExpandIconClicked = (filterId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        
        const isExpanded = isFilterExpanded(filterId);
        if (isExpanded) {
            subActionCollapse(filterId);
        } else {
            subActionExpand(filterId);
        }
    }

    const subActionExpand = (filterId: number) => {

        const filter = baseData.filter_map.get(filterId);
        if (filter === undefined) {
            console.error("Filter not found in filters map, filter id: "+filterId);
            return;
        }

        if (!isFilterInActiveSearchTree(filterId)) {
            // Clears search when non search filter is expanded.
            setSearchText('');
            setSearchCurrentFilterId(0);
            setSearchResultMap(new Map());
        }

        let newExpandedIds : Array<number> = [];
        if (isFilterDecendantOfSearchFilter(filter, searchCurrentFilterId)) {
            // In this case, do not close other expanded.
            newExpandedIds = [...expandedFilterIds];
        }
        
        // Set expanded for all filters until root is reached.
        let tempFilter : ApiObjFilter|undefined = filter;
        while (tempFilter !== undefined) {
            newExpandedIds.push(tempFilter.id);
            tempFilter = baseData.filter_map.get(tempFilter.parent_id);
        }
        
        newExpandedIds = UtilArray.createUniqueSortedIntArray(newExpandedIds);
        setExpandedFilterIds(newExpandedIds);
        const newVisible = calculateVisible(newExpandedIds, searchCurrentFilterId, searchResultMap);
        onVisibleFilterIdsChanged(newVisible);

        const isSelected = selectedFilterIds.includes(filterId);

        // Special case: Selected and has children => select all children.
        if (isSelected && filter.children.length > 0) {
            const newSelectedIds = [...selectedFilterIds];
            for (const child of filter.children) {
                newSelectedIds.push(child.id);
            }
            const sortedArray2 = UtilArray.createUniqueSortedIntArray(newSelectedIds);
            onSelectedFilterIdsChanged(sortedArray2)
        }
    }

    const subActionCollapse = (filterId: number) => {
        const newArray = UtilArray.removeArrayFromArray([filterId], expandedFilterIds);
        setExpandedFilterIds(newArray);
        const newVisible = calculateVisible(newArray, searchCurrentFilterId, searchResultMap);
        onVisibleFilterIdsChanged(newVisible);
    }

    // ===========================================================
    // === Actions, select / unselect
    // ===========================================================

    const actionCheckboxClicked = (filterId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        
        if (disabled) {
            return;
        }

        const selectionState = selectionStateMap.get(filterId);

        const isSelected = isFilterSelected(filterId);
        const hasSelectedChildren = selectionState !== undefined && selectionState.selected_child_count > 0;

        if (isSelected) {
            subActionUnselectFilter(filterId);
        } else if (hasSelectedChildren) {
            subActionUnselectAllChildren(filterId);
        } else {
            subActionSelectFilter(filterId);
        }
    }

    const subActionSelectFilter = (filterId: number) => {
        const filterIdsToAdd = [filterId];

        const isExpanded = expandedFilterIds.includes(filterId);
        if (isExpanded) {
            const filter = baseData.filter_map.get(filterId);
            if (filter !== undefined) {
                for (const child of filter.children) {
                    filterIdsToAdd.push(child.id);
                }
            }
        }

        let newArray = [...filterIdsToAdd, ...selectedFilterIds];
        newArray = UtilArray.createUniqueSortedIntArray(newArray);
        onSelectedFilterIdsChanged(newArray);
    }

    const subActionUnselectFilter = (filterId: number) => {

        const filterIdsToRemove = [filterId];

        // Unselect children if valid.
        const filter = baseData.filter_map.get(filterId);
        if (filter !== undefined && filter.children.length > 0) {
            let allChildrenSelected = true;
            for (const child of filter.children) {
                if (!selectedFilterIds.includes(child.id)) {
                    allChildrenSelected = false;
                    break;
                }
            }
            if (allChildrenSelected) {
                for (const child of filter.children) {
                    filterIdsToRemove.push(child.id);
                }
            }
        }

        // Removes all selected parents.
        if (filter !== undefined && filter.parent_id !== -1) {
            let currentParentId = filter.parent_id;
            let done = false;
            while (!done) {
                filterIdsToRemove.push(currentParentId);
                const tempParentFilter = baseData.filter_map.get(currentParentId);
                if (tempParentFilter === undefined || tempParentFilter.parent_id === -1) {
                    done = true;
                } else {
                    currentParentId = tempParentFilter.parent_id;
                }
            }
        }
        

        const newFilterIds = UtilArray.removeArrayFromArray(filterIdsToRemove, selectedFilterIds);
        onSelectedFilterIdsChanged(newFilterIds);
    }

    const subActionUnselectAllChildren = (filterId: number) => {
        
        const children : Array<ApiObjFilter> = [];
        baseData.getFilterAllChildren(filterId, children);
        const idsToRemove = [];
        for (const child of children) {
            idsToRemove.push(child.id);
        }
        const newFilterIds = UtilArray.removeArrayFromArray(idsToRemove, selectedFilterIds);
        onSelectedFilterIdsChanged(newFilterIds);
    }

    // ===========================================================
    // === Actions, visible
    // ===========================================================

    const calculateVisible = (newExpandedFilterIds: Array<number>, newSearchFilterId: number, newSearchResultMap: Map<number, SearchResult>) : Array<number> => {

        const resultVisible : Array<number> = [];

        for (const filter of baseData.filter_tree) {
            calculateVisibleRecursive(filter, newExpandedFilterIds, newSearchFilterId, newSearchResultMap, resultVisible);
        }
        const sortedArray = UtilArray.createUniqueSortedIntArray(resultVisible);
        return sortedArray;
    }

    const calculateVisibleRecursive = (filter: ApiObjFilter, newExpandedFilterIds: Array<number>, newSearchFilterId: number, newSearchResultMap: Map<number, SearchResult>, resultVisible: Array<number>) => {

        const isAffectedBySearch = isFilterDecendantOfSearchFilter(filter, newSearchFilterId);

        const searchCheckPass = (
            !isAffectedBySearch
            || (
                isAffectedBySearch
                && newSearchResultMap.has(filter.id)
            )
        );

        const isFilterEnabledForUser = userData.isFilterEnabled(filter);

        if (!(searchCheckPass && isFilterEnabledForUser)) {
            return;
        }

        resultVisible.push(filter.id);

        if (filter.children.length === 0 || !newExpandedFilterIds.includes(filter.id)) {
            // No children can be visible.
            return;
        }

        for (const child of filter.children) {
            calculateVisibleRecursive(child, newExpandedFilterIds, newSearchFilterId, newSearchResultMap, resultVisible);
        }
    }

    // ===========================================================
    // === Actions, search
    // ===========================================================

    const actionSearchTextChange = (searchFilterId: number, newText: string) => {

        if (newText === '') {
            actionClearSearch();
            return;
        }

        setSearchCurrentFilterId(searchFilterId);
        setSearchText(newText);

        const filter = baseData.filter_map.get(searchFilterId);
        if (filter === undefined) {
            console.error("Filter not found here. Very unexpected.");
            return;
        }

        const tempSearchText = newText.toLowerCase();
        const newSearchResultMap : Map<number, SearchResult> = new Map();
        calculateSearchRecursive(filter, tempSearchText, newSearchResultMap);
        setSearchResultMap(newSearchResultMap);

        // Set expanded filters
        const tempResult : Array<number> = [];
        const filtersToRoot = baseData.getFilterAllParents(filter.id);
        filtersToRoot.unshift(filter);
        for (const f of filtersToRoot) {
            tempResult.push(f.id);
        }
        calculateSearchExpandedRecursive(filter, newSearchResultMap, tempResult);
        const newExpandedFilterIds = UtilArray.createUniqueSortedIntArray(tempResult);
        setExpandedFilterIds(newExpandedFilterIds);
        
        // Set visible filters
        const newVisible = calculateVisible(newExpandedFilterIds, searchFilterId, newSearchResultMap);
        onVisibleFilterIdsChanged(newVisible);
    }

    const calculateSearchRecursive = (filter: ApiObjFilter, searchText: string, resultMap: Map<number, SearchResult>) : void => {

        let childMatchCount = 0;
        for (const child of filter.children) {
            calculateSearchRecursive(child, searchText, resultMap);
            if (resultMap.has(child.id)) {
                childMatchCount ++;
            }
        }

        // The actual search logic
        let match = false;
        const nameParts = filter.name.split(' ');
        nameParts.push(filter.value_1+'');
        for (const part of nameParts) {
            const tempPart = part.toLowerCase();
            if (tempPart.substring(0, searchText.length) === searchText) {
                match = true;
                break;
            }
        }

        if (!match && childMatchCount === 0) {
            return undefined;
        }

        let expanded = false;
        if (filter.children.length === 1 &&  childMatchCount > 0) {
            expanded = true;
        }

        for (const child of filter.children) {
            const childResult = resultMap.get(child.id);
            if (childResult !== undefined) {
                expanded = true;
                break;
            }
        }

        const searchResult = {
            filter: filter,
            is_direct_match: match,
            children_match_count: childMatchCount,
        }
        resultMap.set(filter.id, searchResult);
    }

    const calculateSearchExpandedRecursive = (filter: ApiObjFilter, searchResultMap: Map<number, SearchResult>, result: Array<number>) : void => {
        
        const newExpandedFilterIds : Array<number> = [];

        const tempFilters = baseData.getFilterAllParents(filter.id);
        for (const f of tempFilters) {
            newExpandedFilterIds.push(f.id);
        }

        let childMatchCount = 0;
        let lastChild : undefined|ApiObjFilter = undefined
        for (const child of filter.children) {
            if (searchResultMap.has(child.id)) {
                childMatchCount ++;
                lastChild = child;
            }
        }

        if (childMatchCount === 0) {
            // Do not expand filter
            return;

        } else if (childMatchCount === 1) {
            // Expand filter and child (and possibly further).
            result.push(filter.id);
            if (lastChild !== undefined) {
                calculateSearchExpandedRecursive(lastChild, searchResultMap, result);
            }

        } else {
            // Expand filter but not child.
            result.push(filter.id);
        }
    }

    const actionClearSearch = () => {

        const searchFilter = baseData.filter_map.get(searchCurrentFilterId);
        if (searchFilter !== undefined) {

            const filterParents = baseData.getFilterAllParents(searchFilter.id);
            const tempResult : Array<number> = [];
            tempResult.push(searchFilter.id);

            for (const parent of filterParents) {
                tempResult.push(parent.id);
            }
            const newExpandedFilterIds = UtilArray.createUniqueSortedIntArray(tempResult);
            setExpandedFilterIds(newExpandedFilterIds);
        
            // Set visible filters
            const newVisible = calculateVisible(newExpandedFilterIds, 0, new Map());
            onVisibleFilterIdsChanged(newVisible);
        }

        setSearchText('');
        setSearchCurrentFilterId(0);
        setSearchResultMap(new Map());
    }

    // ===========================================================
    // === Actions, select search result
    // ===========================================================

    const actionSelectSearchResult = () => {
        const newIds : Array<number> = [];

        const searchFilter = baseData.filter_map.get(searchCurrentFilterId);
        if (searchFilter === undefined) {
            console.log("Searchfilter not found here. Unexpected.");
            return;
        }

        selectSearchResultRecursive(searchFilter, newIds);

        let tempArray : Array<number> = [...selectedFilterIds, ...newIds];
        tempArray = UtilArray.createUniqueSortedIntArray(tempArray);

        onSelectedFilterIdsChanged(tempArray);
    }

    const selectSearchResultRecursive = (filter: ApiObjFilter, result: Array<number>) => {

        const searchResult = searchResultMap.get(filter.id);
        if (searchResult === undefined) {
             // No match (direct nor indirect), stop searching
             return;
        }

        const allChildren : Array<ApiObjFilter> = [];
        baseData.getFilterAllChildren(filter.id, allChildren);
        
        let allChildrenIsMatching = true;
        for (const child of allChildren) {
            if (!userData.isFilterEnabled(child)) {
                continue;
            }
            if (!searchResultMap.has(child.id)) {
                allChildrenIsMatching = false;
                break;
            }
        }

        if (allChildrenIsMatching) {
            // End found, do not dig deeper.
            result.push(filter.id);
            return;
        }

        // No perfect match, dig deeper.
        for (const child of filter.children) {
            selectSearchResultRecursive(child, result);
        }
    }

    // ===========================================================
    // === Helpers
    // ===========================================================

    const isFilterSelected = (filterId: number) => {
        for (const id of selectedFilterIds) {
            if (id === filterId) {
                return true;
            }
        }
        return false;
    }

    const isFilterExpanded = (filterId: number) => {
        for (const id of expandedFilterIds) {
            if (id === filterId) {
                return true;
            }
        }
        return false;
    }

    const isFilterInActiveSearchTree = (filterId: number) => {
        if (searchCurrentFilterId === filterId) {
            return true;
        }
        let curFilter = baseData.filter_map.get(filterId);
        while(curFilter !== undefined) {
            if (curFilter.id === searchCurrentFilterId) {
                return true;
            }
            curFilter = baseData.filter_map.get(curFilter.parent_id);
        }
        return false;
    }

    const isFilterDecendantOfSearchFilter = (filter: ApiObjFilter, searchFilterId: number) => {
        if (searchFilterId === 0) {
            return false;
        }
        if (filter.id === searchFilterId) {
            return false;
        }
        let tempFilter : ApiObjFilter|undefined = filter;
        while (tempFilter !== undefined) {
            if (tempFilter.id === searchFilterId) {
                return true;
            }
            tempFilter = baseData.filter_map.get(tempFilter.parent_id);
        }
        return false;
    }

    const getDynamicCountItem = (filterId: number) : CountFilterDataItem|undefined => {
        for (const item of filterCounts) {
            if (item.filter_id === filterId) {
                return item;
            }
        }
        return undefined;
    }

    // ===========================================================
    // === Render
    // ===========================================================

    const renderFilter = (filter: ApiObjFilter, level: number) : Array<JSX.Element> => {

        // --- Handle premium filter ---
        if (filter.is_premium) {
            const filterEnabled = userData.isLoggedIn()
                && userData.api_data !== undefined
                && userData.api_data.isPremiumFilterEnabled(filter.id);

            if (!filterEnabled) {
                return [];
            }
        }

        const isSelected = isFilterSelected(filter.id);
        const isExpanded = isFilterExpanded(filter.id);
        const isHover = mouseOverFilterId === filter.id
        const selectionState = selectionStateMap.get(filter.id);
        const selectedChildCount = selectionState === undefined ? 0 : selectionState.selected_child_count;

        // --- Expand box ---
        let expandIconBox = undefined;
        if (filter.children.length > 0) {
            if (isExpanded) {
                expandIconBox = (
                    <Box 
                        width={'25px'}
                        onClick={(event) => {actionExpandIconClicked(filter.id, event)}}>
                        <ExpandMoreIcon />
                    </Box>
                );
            } else {
                expandIconBox = (
                    <Box width={'25px'}>
                        <ChevronRightIcon />
                    </Box>
                );
            }
        } else {
            expandIconBox = (<Box width={'25px'}></Box>);
        }

        // --- Checkbox box ---
        let checkboxBox = undefined;
        if (filter.is_selectable) {
            checkboxBox = (
                <Box
                    onClick={(event) => actionCheckboxClicked(filter.id, event)}>

                    <Checkbox
                        checked={isSelected}
                        indeterminate={!isSelected && selectedChildCount !== 0}
                        color={'secondary'}
                        disabled={disabled}
                    />
                </Box>
            )
        }

        // --- Name box ---
        const nameBox = (
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}>
                    {filter.name}
            </Box>
        );

        // --- Selection count ---
        let selectionCountBox = undefined;
        if (selectedChildCount > 0) {
            selectionCountBox = (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginLeft={'14px'}>
                        <Chip label={selectedChildCount} size='small' color="secondary" />
                </Box>
            );
        }

        // --- Count static ---
        let countStaticBox = undefined;
        if (filter.is_selectable) {
            const tempCount = util_strings_format_with_spaces(filter.getCount(nixOptionId));
            countStaticBox = (
                <Box style={{color: '#999999'}}>
                    {tempCount}
                </Box>
            );
        }

        // --- Count dynamic ---
        let countDynamicBox = undefined;
        if (filter.is_selectable) {
            const countItem = getDynamicCountItem(filter.id);
            if (countItem === undefined || countItem.state !== CountFilterDataItem.STATE_READY) {
                countDynamicBox = (
                    <Box marginRight={'8px'}>
                        <CircularProgress size={16} />
                    </Box>
                );
            } else {
                const tempCount = util_strings_format_with_spaces(countItem.count);
                countDynamicBox = (
                    <Box marginRight={'8px'}>
                        {tempCount}
                    </Box>
                );
            }
        }

        // --- Price Box ---
        let priceBox = undefined;
        if (!disablePrices && userData.isLoggedIn() && userData.api_data !== undefined) {
            const priceObject = userData.api_data.getPriceObjectFromType(ApiObjPriceObjectType.ID_LEAD_PRICE_FILTER, filter.id);
            if (priceObject !== undefined) {
                if (priceObject.getPriceFloat() > 0) {
                    priceBox = (
                        <Box marginRight={'8px'}>
                            <Chip label={priceObject.getPriceStringFull()} variant="filled" />
                        </Box>
                    );
                }
            }
        }

        // --- Search box ---
        let searchBox = undefined;
        if (isExpanded && FILTERS_WITH_SEARCH_FIELD.includes(filter.id)) {
            const isSearchActive = searchCurrentFilterId !== 0;

            let buttonSelectSearch : undefined|JSX.Element = undefined;
            if (isSearchActive) {
                buttonSelectSearch = (
                    <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        style={{textTransform:'none'}}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            actionSelectSearchResult();
                        }}>
                        Markera sökning
                    </Button>
                );  
            }
            const buttonClearSearch = (
                <IconButton
                    color='primary'
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        actionClearSearch();
                    }}>
                    <IconClear />
                </IconButton>
            );
            searchBox = (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'10px'}
                    alignItems={'center'}>

                    {buttonSelectSearch}
                    <Box>
                        Sök
                    </Box>
                    <TextField
                        variant="outlined"
                        autoComplete='off'
                        size={'small'}
                        disabled={disabled}
                        value={searchText}
                        onChange={(e) => {
                            actionSearchTextChange(filter.id, e.target.value)
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        InputProps={{
                            endAdornment: buttonClearSearch,
                        }}
                    />
                </Box>
            );
        }
        

        // --- Tree item asseble ---
        let elements : Array<JSX.Element> = [];
        elements.push(
            <Box
                key={filter.id}
                sx={{cursor:'pointer', backgroundColor: isHover ? '#f5f5f5' : ''}}
                marginLeft={(level * 25)+'px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'40px'}
                onClick={(event) => {actionItemBodyClicked(filter.id, event)}}
                onMouseEnter={() => {actionItemBodyMouseEnter(filter.id)}}
                onMouseLeave={() => {actionItemBodyMouseExit(filter.id)}}>
                
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'start'}
                    alignItems={'center'}>
                        {expandIconBox}
                        {checkboxBox}
                        {nameBox}
                        {selectionCountBox}
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'start'}
                    alignItems={'center'}>
                        {searchBox}
                        {priceBox}
                        {countDynamicBox}
                        {countStaticBox}
                </Box>
            </Box>
        );

        // --- All Children ---
        if (filter.children.length > 0) {
            for (const childFilter of filter.children) {
                if (visibleFilterIds.includes(childFilter.id)) {
                    elements = [...elements, ...renderFilter(childFilter, level + 1)];
                }
            }
        }

        return elements;
    }

    const renderTopFilters = () : Array<JSX.Element> => {
        let elements: Array<JSX.Element> = [];
        for (const filter of baseData.filter_tree) {
            elements = [...elements, ...renderFilter(filter, 0)];
        }
        return elements;
    }

    return (
        <Box mb={4}>
            {renderTopFilters()}
        </Box>
    );
}