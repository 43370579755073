import { api_get_my_user } from '../../api/endpoint/user/api_get_my_user';
import { api_login } from '../../api/endpoint/user/api_login';
import { api_logout } from '../../api/endpoint/user/api_logout';
import { api_update_my_user_details } from '../../api/endpoint/my_user/api_update_my_user_details';
import { UserData } from './UserData';

export interface NewUserData {
    first_name?: string,
    last_name?: string,
    company_name?: string,
    company_id?: string,
    address?: string,
    zipcode?: string,
    city?: string,
    phone_number?: string,
}

export class UserManager {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    private userData: UserData;
    private callbackNewUserData: undefined|((userData: UserData) => void);

    // ==========================================================================
    // === Create
    // ==========================================================================

    constructor() {
        this.userData = UserData.createNew();
        this.callbackNewUserData = undefined;
        this.startFetchUserData();
    }

    /**
     * Sets callback. Only to be used from top parent.
     */
    public setCallbackNewUserData (callback: (newUserData: UserData) => void) {
        const isNewCallback = this.callbackNewUserData !== callback;
        this.callbackNewUserData = callback;
        if (isNewCallback) {
            this.tryMakeCallback();
        }
    }

    // ==========================================================================
    // === Public Actions
    // ==========================================================================

    public actionLogIn = (username: string, password: string): void => {

        const hasCorrectState = this.userData.isIdle() && !this.userData.isLoggedIn();        
        if (!hasCorrectState) {
            console.error("UserManager: Cannot login, wrong state. State: "+this.userData.state);
            return;
        }

        this.userData.state = UserData.STATE_LOGGING_IN;
        this.tryMakeCallback();

        api_login(username, password)
            .then((result) => {

                if (this.userData.state !== UserData.STATE_LOGGING_IN) {
                    console.error("UserManager: Login success but unexpected state: "+this.userData.state+ " aborting.");
                    return;
                }
                
                this.userData.api_data = result;
                this.userData.state = UserData.STATE_READY_LOGGED_IN;
                this.tryMakeCallback();

            }).catch((err) => {
                console.error("UserManager: Login failed");
                console.error(err);
                this.userData.state = UserData.STATE_LOGIN_FAILED;
                this.userData.lastErrorMessage = 'Inloggning misslyckades.'; // TODO ERIK bättre felmeddelande.
                this.tryMakeCallback();
            });
    }

    public actionLogOut = (): void => {

        const hasCorrectState = this.userData.isLoggedIn() && this.userData.isIdle();
        if (!hasCorrectState) {
            console.error("UserManager: Cannot logout, wrong state: "+this.userData.state);
            return;
        }

        this.userData.state = UserData.STATE_LOGGING_OUT;
        this.tryMakeCallback();

        api_logout()
            .then((result) => {
                if (this.userData.state === UserData.STATE_LOGGING_OUT) {
                    console.error("UserManager: Logout success but unexpected state. Continuing anyway.");
                }
                this.userData.state = UserData.STATE_READY_LOGGED_OUT;
                this.userData.api_data = undefined;
                this.tryMakeCallback();

            }).catch((err) => {
                console.error("UserManager: Logout failed should rarely happen.");
                console.error(err);
                if (this.userData.state === UserData.STATE_LOGGING_OUT) {
                    console.error("UserManager: Logout failed, unexpected state. Continuing anyway.");
                }
                this.userData.state = UserData.STATE_READY_LOGGED_IN;
                this.tryMakeCallback();
            });
    }

    public actionUpdateUserData = (newUserData: NewUserData): void => {

        const hasCorrectState = this.userData.isLoggedIn() && this.userData.isIdle();
        if (!hasCorrectState) {
            console.error("UserManager: Cannot update user, wrong state: "+this.userData.state);
            return;
        }

        const userId = this.userData.api_data?.id;
        if (userId === undefined) {
            console.error("UserManager: Cannot update user, cannot find userId.");
            return;
        }

        this.userData.state = UserData.STATE_UPDATING_USER;
        this.tryMakeCallback();

        api_update_my_user_details(newUserData)
            .then((result: {success: boolean}) => {

                if (this.userData.state !== UserData.STATE_UPDATING_USER) {
                    console.error("UserManager: Cannot update user, wrong state: "+this.userData.state);
                    return;
                }

                // TODO ERIK ändra api endpointet så det returnerar den användarens nya information. Så man slipper detta fula nedan.
                api_get_my_user()
                    .then((result) => {

                        if (result.logged_in) {
                            this.userData.api_data = result.user_data;
                            this.userData.state = UserData.STATE_READY_LOGGED_IN;
                            this.tryMakeCallback();

                        } else {
                            console.error("Unexpected case. Get user returns logged in false. Setting state to logged out.");
                            this.userData.api_data = undefined;
                            this.userData.state = UserData.STATE_READY_LOGGED_OUT;
                            this.tryMakeCallback();
                        }
                    })
                    .catch((err) => {
                        console.error("UserManager: Could not update user.");
                        console.error(err);
                        this.userData.state = UserData.STATE_UPDATE_USER_FAILED;
                        this.userData.lastErrorMessage = 'Oväntat fel, kunde inte hämta användaren.';    // TODO ERIK bättre felmeddelande.
                        this.tryMakeCallback();
                    });
            })
            .catch((err) => {
                console.error("UserManager: Could not update user.");
                console.error(err);
                this.userData.state = UserData.STATE_UPDATE_USER_FAILED;
                this.userData.lastErrorMessage = 'Oväntat fel, kunde inte spara informationen.';    // TODO ERIK bättre felmeddelande.
                this.tryMakeCallback();
            });
    }

    public actionReloadUserData = () => {
        api_get_my_user()
            .then((result) => {

                if (result.logged_in) {
                    this.userData.api_data = result.user_data;
                    this.userData.state = UserData.STATE_READY_LOGGED_IN;
                    this.tryMakeCallback();

                } else {
                    console.error("Unexpected case. Get user returns logged in false. Setting state to logged out.");
                    this.userData.api_data = undefined;
                    this.userData.state = UserData.STATE_READY_LOGGED_OUT;
                    this.tryMakeCallback();
                }
            })
            .catch((err) => {
                console.error("UserManager: Could not fetch user data.");
                console.error(err);
                this.tryMakeCallback();
            });
    }

    // ==========================================================================
    // === Private Actions
    // ==========================================================================

    private tryMakeCallback = () => {
        if (this.callbackNewUserData === undefined) {
            return;
        }
        const newUserData = UserData.createClone(this.userData);
        this.callbackNewUserData(newUserData);
    }

    private startFetchUserData = () => {

        if (this.userData.state !== UserData.STATE_INITIAL) {
            console.error("UserManager: Cannot start fetch user, wrong state: "+this.userData.state);
            return;
        }

        this.userData.state = UserData.STATE_TRY_FETCHING_USER;
        this.tryMakeCallback();

        api_get_my_user()
            .then((result) => {

                if (this.userData.state !== UserData.STATE_TRY_FETCHING_USER) {
                    console.error("UserManager: User fetch complete but unexpected state: "+this.userData.state);
                    return;
                }

                if (!result.logged_in) {
                    this.userData.state = UserData.STATE_READY_LOGGED_OUT;
                    this.userData.api_data = undefined;
                    this.tryMakeCallback();

                } else {
                    this.userData.state = UserData.STATE_READY_LOGGED_IN;
                    this.userData.api_data = result.user_data;
                    this.tryMakeCallback();
                }
            })
            .catch((err) => {

                if (this.userData.state !== UserData.STATE_TRY_FETCHING_USER) {
                    console.error("UserManager: User fetch failed and unexpected state: "+this.userData.state+" abouting.");
                    return;
                }
                console.error("UserManager: Error fetching user. Settings state to logged out.");
                this.userData.state = UserData.STATE_READY_LOGGED_OUT;
                this.tryMakeCallback();
            });
    }
}