import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';
import { ApiObjAdminNixEntry } from '../../../object/admin/ApiObjAdminNixEntry';

export const api_admin_get_nix_entry = (number: string) : Promise<undefined|ApiObjAdminNixEntry> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/admin/nix?phone_number='+number;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            if (resp.data == undefined || resp.data == '') {
                return resolve(undefined);
            }
            return resolve(new ApiObjAdminNixEntry(resp.data));

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}