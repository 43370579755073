import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjUserMy } from '../../object/user/ApiObjUserMy';

export const api_login = (email: string, password: string) : Promise<ApiObjUserMy> => {

    return new Promise <ApiObjUserMy> ((resolve, reject) => {

        const url = API_URL+'/user/login';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        const data = {
            email: email,
            password:  password,
        }
        Axios.post(
            url,
            data,
            config
        )
        .then((resp) => {
            resolve(new ApiObjUserMy(resp.data));
            
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}