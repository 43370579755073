import { Box, Button, TextField } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState } from 'react';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserManager } from '../../../data_layer/user/UserManager';
import { api_change_my_user_password } from '../../../api/endpoint/my_user/api_change_my_user_password';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    userManager: UserManager
}

export const MyUserTabChangePassword: FunctionComponent <Props> = ({
    baseData,
    userData,
    userManager,
}) => {

    const STATE_1_IDLE = 1;
    const STATE_2_SAVING = 2;
    const STATE_3_SUCCESS = 3;
    const STATE_4_FAILED = 4;

    const [currentPassword, setCurrentPassword] = useState <string> ('');
    const [newPassword, setNewPassword] = useState <string> ('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState <string> ('');

    const [isTouchedCurrentPassword, setIsTouchedCurrentPassword] = useState <boolean> (false);
    const [isTouchedNewPassword, setIsTouchedNewPassword] = useState <boolean> (false);

    const [processState, setProcessState] = useState <number> (STATE_1_IDLE);
    
    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionSave = () => {

        if (processState === STATE_2_SAVING) {
            return;
        }
        if (!isAllValuesValid()) {
            return;
        }
        setProcessState(STATE_2_SAVING);

        api_change_my_user_password(currentPassword, newPassword)
            .then((result) => {
                setProcessState(STATE_3_SUCCESS);
                setCurrentPassword('');
                setNewPassword('');
                setNewPasswordRepeat('');
                setIsTouchedCurrentPassword(false);
                setIsTouchedNewPassword(false);
            }).catch((err) => {
                console.error("Could not set password.");
                setProcessState(STATE_4_FAILED);
            });
    }

    // ======================================================================
    // === Field Validation
    // ======================================================================

    const getErrorPassword = () => {
        if (currentPassword.length === 0) {
            return "Ange nuvarande lösenord";
        }
        return undefined;
    }

    const getErrorNewPassword = () => {
        if (newPassword.length === 0) {
            return "Ange nytt lösenord";
        } else if (newPassword.length < 8) {
            return "Måste vara minst 8 tecken";
        } else if (newPassword.length > 50) {
            return "För långt";
        }
        return undefined;
    }

    const getErrorNewPasswordRepeat = () => {
        if (newPassword !== newPasswordRepeat) {
            return "Matchar inte";
        }
        return undefined;
    }

    // ======================================================================
    // === Checks
    // ======================================================================

    const isAllValuesValid  = () => {

        const allCheckerFunctions = [
            getErrorPassword,
            getErrorNewPassword,
            getErrorNewPasswordRepeat,
        ];

        for (const item of allCheckerFunctions) {
            if (item() !== undefined) {
                return false;
            }
        }
        return true;
    }

    // ======================================================================
    // === Render elements
    // ======================================================================

    const styleFormItemBox = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        alignItems: 'start',
        gap: '12px'
    } as React.CSSProperties;

    const renderCurrentPassword = () : JSX.Element => {

        let errorText = undefined;
        if (isTouchedCurrentPassword) {
            errorText = getErrorPassword();
        }

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    type="password"
                    size={'medium'}
                    label={'Nuvarande lösenord'}
                    required
                    disabled={processState === STATE_2_SAVING}
                    value={currentPassword}
                    onChange={(e) => {
                        setCurrentPassword(e.target.value);
                        setIsTouchedCurrentPassword(true);
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    const renderNewPassword = () : JSX.Element => {

        let errorText = undefined;
        if (isTouchedNewPassword) {
            errorText = getErrorNewPassword();
        }
        const errorTextRepeat = getErrorNewPasswordRepeat();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    type="password"
                    size={'medium'}
                    label={'Nytt lösenord'}
                    required
                    disabled={processState === STATE_2_SAVING}
                    value={newPassword}
                    onChange={(e) => {
                        setNewPassword(e.target.value);
                        setIsTouchedNewPassword(true);
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '300px'}}
                />
                <TextField
                    variant="outlined"
                    type="password"
                    size={'medium'}
                    label={'Upprepa nytt lösenord'}
                    required
                    disabled={processState === STATE_2_SAVING}
                    value={newPasswordRepeat}
                    onChange={(e) => {
                        setNewPasswordRepeat(e.target.value)
                    }}
                    error={errorTextRepeat !== undefined}
                    helperText={errorTextRepeat}
                    style = {{width: '300px'}}
                />
            </Box>
        );
    }

    const renderStateFeedbackElement = () : JSX.Element|undefined => {
        switch (processState) {
            case STATE_2_SAVING:
                return (<Box>Byter lösenord...</Box>);
            case STATE_3_SUCCESS:
                return (<Box style={{color: '#00EE00'}}>Lyckades</Box>);
            case STATE_4_FAILED:
                return (<Box style={{color: '#EE0000'}}>Kunde inte byta lösenord.</Box>);
        }
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box
                component="form"
                noValidate
                autoComplete="off">

                {renderCurrentPassword()}
                {renderNewPassword()}

            </Box>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                paddingTop={'25px'}
                paddingBottom={'10px'}>

                <Button
                    variant='contained'
                    color='secondary'
                    size={'large'}
                    onClick={actionSave}
                    disabled={processState === STATE_2_SAVING || !isAllValuesValid()}>
                        Byt lösenord
                </Button>
                <Box paddingLeft={'10px'}>
                    {renderStateFeedbackElement()}
                </Box>
            </Box>
        </Box>
    );
}
