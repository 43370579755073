import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { MapAreaCircle } from '../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../data_layer/map/MapAreaPolygon';
import { UtilArray } from '../../../util/code/UtilArray';
import { ApiObjCountFilters } from '../../object/filter/ApiObjCountFilters';

export const api_get_filter_count_filter = (
    filterIdsSelected: Array<number>,
    filterIdsToCheck: Array<number>,
    nixOptionId: number,
    sourceId: number,
    mapAreas: Array<MapAreaCircle|MapAreaPolygon>,
) => {

    return new Promise<ApiObjCountFilters> ((resolve, reject) => {

        const paramFilterIdsSelected = [...filterIdsSelected];
        const paramNixOptionId = nixOptionId;
        const paramSourceId = sourceId;
        let tempArray = [];
        for (const item of mapAreas) {
            tempArray.push(item.id);
        }
        tempArray = UtilArray.createUniqueSortedIntArray(tempArray);
        const paramAreaIds = tempArray;

        const url = API_URL+'/data/count-filters';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        const payloadMapAreas = [];
        for (const mapArea of mapAreas) {
            if (mapArea instanceof MapAreaCircle) {
                payloadMapAreas.push({
                    type: 'circle',
                    center_lat: mapArea.centerPoint.latitude,
                    center_long: mapArea.centerPoint.longitude,
                    radius: mapArea.radius
                });

            } else if (mapArea instanceof MapAreaPolygon) {
                const tempPoints = [];
                for (const point of mapArea.points) {
                    tempPoints.push({
                        lat: point.latitude,
                        long: point.longitude,
                    });
                }
                payloadMapAreas.push({
                    type: 'polygon',
                    points: tempPoints
                });
            } else {
                throw new Error("Unexpected map area type");
            }
        }

        const data = {
            nix_option_id: nixOptionId,
            source_id: sourceId,
            filter_ids_selected: filterIdsSelected,
            filter_ids_to_check:  filterIdsToCheck,
            map_areas: payloadMapAreas
        }
        Axios.post(
            url,
            data,
            config
        )
        .then((resp) => {
            const items = [];
            for (const item of resp.data) {
                items.push(
                    {
                        filter_id: parseInt(item.filter_id),
                        count: parseInt(item.count)
                    }
                );
            }
            return resolve({
                param_selected_filter_ids: paramFilterIdsSelected,
                param_nix_option_id: paramNixOptionId,
                param_source_id: paramSourceId,
                param_map_area_ids: paramAreaIds,
                result: items
            });

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}