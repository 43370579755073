import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_get_single = (populateFileId: number) : Promise<ApiObjPopulateFile> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/populate_file/'+populateFileId;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            resolve(new ApiObjPopulateFile(resp.data));
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}