import React, { FunctionComponent, useState, useEffect } from 'react';
import { IconButton, CircularProgress, Select, MenuItem, ListSubheader, Box, Paper, TextField, Button, Typography, Autocomplete, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import { util_strings_format_with_spaces, util_strings_format_price_w_decimals } from '../../../util/strings/util_strings';
import { CountOrderData } from '../../../data_layer/count/order/CountOrderData';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { OrderData } from '../../../data_layer/order/OrderData';
import { OrderManager } from '../../../data_layer/order/OrderManager';
import { UserData } from '../../../data_layer/user/UserData';
import { ApiObjTargetGroup } from '../../../api/object/target_group/ApiObjTargetGroup';
import { api_get_target_groups } from '../../../api/endpoint/target_group/api_get_target_groups';
import { api_get_stopfiles } from '../../../api/endpoint/stopfile/api_get_stopfiles';
import { ApiObjStopfile } from '../../../api/object/stopfile/ApiObjStopfile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import { ApiObjExportField } from '../../../api/object/export/ApiObjExportField';
import { FilterTreeMiniItem } from '../../../data_layer/filtertree/FilterTreeMiniItem';
import { HouseholdOptionModal } from '../../shared_components/HouseholdOptionModal';
import { ApiObjPriceObjectType } from '../../../api/object/price/ApiObjPriceObjectType';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    orderData: OrderData,
    orderManager: OrderManager
}

export const SpecificationComp: FunctionComponent <Props> = ({
    baseData,
    userData,
    orderData,
    orderManager,
}) => {

    const [targetGroups, setTargetGroups] = useState <Array<ApiObjTargetGroup>> ([]);
    const [stopfiles, setStopfiles] = useState <Array<ApiObjStopfile>> ([]);
    const [selectedTargetGroupId, setSelectedTargetGroupId] = useState <number> (0);
    const [expandedItem, setExpandedItem] = useState <string> ('');
    const [visibleModal, setVisibleModal] = useState <string> ('');
    const navigate = useNavigate();

    // ===============================================================
    // === Loading
    // ===============================================================

    useEffect(() => {

        if (userData.isLoggedIn() && userData.isIdle()) {

            fetchTargetGroups();
            fetchStopfiles();

        } else if (!userData.isLoggedIn() && userData.isIdle()) {
            fetchTargetGroups();
        }
    
    }, [userData]);

    const fetchTargetGroups = () => {
        api_get_target_groups()
            .then((result) => {
                setTargetGroups(result);

                if (selectedTargetGroupId != 0) {
                    // Checks if selected group is still found.
                    let found = false;
                    for (const item of targetGroups) {
                        if (item.id === selectedTargetGroupId) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        setSelectedTargetGroupId(0);
                    }
                }

            }).catch((err) => {
                console.error("Could not fetch target groups");
                console.error(err);
            })
    }

    const fetchStopfiles = () => {
        api_get_stopfiles()
            .then((result) => {

                const validStopfiles: Array<ApiObjStopfile> = [];
                for (const item of result) {
                    if (item.state === ApiObjStopfile.STATE_3_SUCCESS) {
                        validStopfiles.push(item);
                    }
                }
                setStopfiles(validStopfiles);
            
            }).catch((err) => {
                console.error("Could not fetch stopfiles");
                console.error(err);
            });
    }

    // ===============================================================
    // === Actions
    // ===============================================================

    const actionClearAll = () => {
        setSelectedTargetGroupId(0);
        orderManager.actionOrderReset();
    }

    const actionTargetGroupSelected = (targetGroupId: number) => {
        if (targetGroupId !== 0) {
            let foundGroup = undefined;
            for (const item of targetGroups) {
                if (item.id === targetGroupId) {
                    foundGroup = item;
                    break;
                }
            }
            if (foundGroup !== undefined) {
                setSelectedTargetGroupId(targetGroupId);
                orderManager.actionPopulateFromTargetGroup(foundGroup, stopfiles);
            } else {
                console.error("Could not find target group: id: "+targetGroupId);
            }
        } else {
            setSelectedTargetGroupId(0);
        }
       
    }

    const actionOnChangeLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'string') {
            let newValueInt = undefined
            if (newValue === '') {
                newValueInt = 0;
            } else {
                newValueInt = parseInt(newValue);
            }
            if (!isNaN(newValueInt)) {
                if (newValueInt > 100000) {
                    newValueInt = 100000;
                }
                orderManager.actionSetSizeLimit(newValueInt);
            }
        }
    }

    const actionRemoveFilterAndChildren = (filterId: number) => {
        orderManager.actionRemoveFilterAndChildren(filterId);
    }

    const actionReloadTargetGroups = () => {
        if (userData.isLoggedIn() && userData.isIdle()) {
            fetchTargetGroups();
            fetchStopfiles();
        } else if (!userData.isLoggedIn() && userData.isIdle()) {
            fetchTargetGroups();
        }
    }

    const actionModalCancel = () => {
        setVisibleModal('');
    }

    const actionSethouseholdOption = (newHouseholdOption: number) => {
        orderManager.actionSetHouseholdOption(newHouseholdOption);
        setVisibleModal('');
    }

    // ===============================================================
    // === Render title
    // ===============================================================

    const renderTitleBar = () => {
        return (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <Typography variant='h4'>
                        Beställning
                    </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box>
                        Rensa allt
                    </Box>
                    <IconButton onClick={actionClearAll} ><CloseIcon /></IconButton>
                </Box>
            </Box>
        );
    }

    // ===============================================================
    // === Render Selection Main
    // ===============================================================

    const createYourSelection = () => {

        return (
            <Box display={'flex'} flexDirection={'column'} marginTop={'8px'}>
                <Box>
                    <b>Ditt urval</b>
                </Box>
                {renderSelectionTargetGroup()}
                {renderSelectionNixOption()}
                {renderSelectionSource()}
                {renderSelectionFilters()}
                {renderSelectionMapAreas()}
                {renderSelectionExportFields()}
                {renderSelectionStopfiles()}
                {renderSelectionHouseholdOption()}
            </Box>
        );
    }

    // ===============================================================
    // === Render target groups
    // ===============================================================

    const renderSelectionTargetGroup = () => {

        if (targetGroups.length === 0) {
            return undefined;
        }

        const groupsPublic = [];
        const groupsMy = [];
        for (const item of targetGroups) {
            if (item.user_id === 0) {
                groupsPublic.push(item);
            } else {
                groupsMy.push(item);
            }
        }

        const options = [];
        options.push(
            <MenuItem
                onClick={() => {actionTargetGroupSelected(0)}}
                key={0}
                value={0}>
                    {'Välj'}
            </MenuItem>
        );

        options.push(<ListSubheader key={'g1'}>Standard</ListSubheader>);

        for (const item of groupsPublic) {
            options.push(
                <MenuItem
                    onClick={() => {actionTargetGroupSelected(item.id)}}
                    key={item.id}
                    value={item.id}>
                        {item.name}
                </MenuItem>
            );
        }

        options.push(<ListSubheader key={'g2'}>Mina målgrupper</ListSubheader>);

        for (const item of groupsMy) {
            options.push(
                <MenuItem
                    onClick={() => {actionTargetGroupSelected(item.id)}}
                    key={item.id}
                    value={item.id}>
                        {item.name}
                </MenuItem>
            );
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box>
                    Målgrupp:
                </Box>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} marginLeft={'16px'}>
                    {/*<Select
                        size='small'
                        value={selectedTargetGroupId}
                        disabled={!orderData.canEditOrder()}>
                            {options}
        </Select>*/}
                    <Autocomplete
                        disablePortal
                        isOptionEqualToValue={(option) => option.value === selectedTargetGroupId}
                        options={targetGroups.map((item) => ({
                            label: item.name,
                            value: item.id
                        }))}
                        sx={{ width: '276px' }}
                        onChange={ (event: any, newValue) => {
                            if(!newValue) return
                            actionTargetGroupSelected(newValue.value)
                        }}
                        renderInput={(params) => <TextField {...params} label="Välj" />}
                    />
                    <IconButton onClick={actionReloadTargetGroups}>
                        <CachedIcon />
                    </IconButton>
                </Box>
            </Box>
        );
    }

    const renderSelectionNixOption = () => {

        let nixOption = baseData.getNixOption(orderData.nix_option_id);
        if (nixOption === undefined && userData.isLoggedIn() && userData.api_data !== undefined) {
            nixOption = userData.api_data.getNixOption(orderData.nix_option_id);
        }

        let text = '-';
        if (nixOption !== undefined) {
            text = nixOption.name;
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box>Nix Alternativ:</Box>
                </Box>
                <Button
                    size={'small'}
                    style={{textTransform:'none', minWidth: '100px'}}
                    variant='contained'
                    onClick={(event) => { event.stopPropagation(); navigate('/order_leads/1'); }}>
                        {text}
                </Button>
            </Box>
        );
    }

    const renderSelectionSource = () => {

        if (orderData.source_id === 0) {
            return undefined;
        }
        let source = baseData.getSource(orderData.source_id);
        if (source === undefined) {
            if (userData.api_data !== undefined) {
                source = userData.api_data.getSource(orderData.source_id);
            }
        }

        let text = '[hittar ej namn]';
        if (source !== undefined) {
            text = source.name;
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box>Källa:</Box>
                </Box>
                <Button
                    size={'small'}
                    style={{textTransform:'none', minWidth: '100px'}}
                    variant='contained'
                    onClick={(event) => { event.stopPropagation(); navigate('/order_leads/1'); }}>
                        {text}
                </Button>
            </Box>
        );
    }

    const RecursiveAccordion = ({item} : {item: FilterTreeMiniItem}) => {
        return(
            <>
                {
                    item.children.length > 0 ? (
                        <Accordion style={{outline: "none", boxShadow: "none", border: "none", position: "static"}} className='specification_accordion' disableGutters defaultExpanded variant='outlined'>
                        <AccordionSummary           
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                {item.filter.name}
                                <IconButton
                                    size='small'
                                    onClick={() => {actionRemoveFilterAndChildren (item.filter.id)}} >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                item.children.map((item, index) => (
                                    <RecursiveAccordion key={index} item={item}></RecursiveAccordion>
                                ))
                            }
                        </AccordionDetails>
                    </Accordion>
                    ) : (
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            {item.filter.name}
                            <IconButton
                                size='small'
                                onClick={() => {actionRemoveFilterAndChildren (item.filter.id)}} >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )
                }
            </>
        )
    }

    const renderSelectionFilters = () => {
        const text = orderData.filter_ids_selected.length + ' filter';

        const isExpanded = expandedItem === 'filters';
        const expandIcon = isExpanded ? <RemoveIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />

        let expandedContainerElement = undefined;
        if (isExpanded && orderData.filter_tree_mini !== undefined) {
            const elements : Array<JSX.Element> = [];

            for (const treeItem of orderData.filter_tree_mini.root_items) {
                renderItemRecursive(treeItem, elements, 0);
            }

            expandedContainerElement = (
                <Box display={'flex'} flexDirection={'column'} padding={'14px'}>
                    {elements}
                </Box>
            );
        }

        const actionOnClick = () => {
            if (expandedItem === 'filters') {
                setExpandedItem('');
            } else {
                setExpandedItem('filters');
            }
        };

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <Box
                    className='specification'
                    padding={'8px'}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    onClick={actionOnClick}
                >
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>Filter:</Box>
                        {expandIcon}
                    </Box>
                    <Button
                        size={'small'}
                        style={{textTransform:'none', minWidth: '100px'}}
                        variant='contained'
                        onClick={(event) => { event.stopPropagation(); navigate('/order_leads/2'); }}>
                            {text}
                    </Button>
                </Box>
                {
                    isExpanded && orderData.filter_tree_mini?.root_items.map((item, index) => (
                        <RecursiveAccordion item={item} key={index}/>
                    ))
                }
            </Box>
        );
    }

    const renderItemRecursive = (treeItem: FilterTreeMiniItem, elements: Array<JSX.Element>, level: number) => {
        const indent = level * 20 + 'px';
        elements.push(
            <Box 
                key={treeItem.filter.id}
                paddingLeft={indent}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
            >
                <Box>{treeItem.filter.name}</Box>
                <IconButton
                    size='small'
                    onClick={() => {actionRemoveFilterAndChildren (treeItem.filter.id)}} >
                    <CloseIcon />
                </IconButton>
            </Box>
        );
        for (const item of treeItem.children) {
            renderItemRecursive(item, elements, level + 1);
        }
    }

    const renderSelectionMapAreas = () => {

        const text = orderData.map_areas.length + ' områden';

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box>Karta:</Box>
                </Box>
                <Button
                    size={'small'}
                    style={{textTransform:'none', minWidth: '100px'}}
                    variant='contained'
                    onClick={(event) => { event.stopPropagation(); navigate('/order_leads/2'); }}>
                        {text}
                </Button>
            </Box>
        );
    }

    const renderSelectionExportFields = () => {

        const text = orderData.export_field_ids.length + ' fält';

        const isExpanded = expandedItem === 'export_fields';
        const expandIcon = isExpanded ? <RemoveIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />

        let expandedContainerElement = undefined;
        if (isExpanded) {

            const specLines = [];

            let baseExportFields : Array<ApiObjExportField> = [];
            if (userData.isLoggedIn() && userData.api_data !== undefined) {
                baseExportFields = userData.api_data.enabled_export_fields;
            } else {
                baseExportFields = baseData.export_fields;
            }

            let tempKey = 1;
            for (const item of baseExportFields) {

                if (!orderData.export_field_ids.includes(item.id)) {
                    continue;
                }
                specLines.push(
                    <Box key={tempKey} marginTop={'6px'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box>{item.name}</Box>
                    </Box>
                );
                tempKey ++;
            }
            expandedContainerElement = (
                <Box padding={'14px'}>{specLines}</Box>
            );
        }

        const actionOnClick = () => {
            if (expandedItem === 'export_fields') {
                setExpandedItem('');
            } else {
                setExpandedItem('export_fields');
            }
        };

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <Box
                    className='specification'
                    padding={'8px'}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    onClick={actionOnClick}
                >
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>Leveransfält:</Box>
                        {expandIcon}
                    </Box>
                    <Button
                        size={'small'}
                        style={{textTransform:'none', minWidth: '100px'}}
                        variant='contained'
                        onClick={(event) => { event.stopPropagation(); navigate('/order_leads/3'); }}>
                            {text}
                    </Button>
                </Box>
                {expandedContainerElement}
            </Box>
        );
    }

    const renderSelectionStopfiles = () => {

        const text = orderData.stopfile_ids.length + ' stoppfiler';

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <Box
                    className='specification'
                    padding={'8px'}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>Stoppfiler:</Box>
                    </Box>
                    <Button
                        size={'small'}
                        style={{textTransform:'none', minWidth: '100px'}}
                        variant='contained'
                        onClick={(event) => { event.stopPropagation(); navigate('/order_leads/4'); }}>
                            {text}
                    </Button>
                </Box>
            </Box>
        );
    }

    const renderSelectionHouseholdOption = () => {
        
        let householdOptions = [];
        if (userData.api_data !== undefined) {
            householdOptions = userData.api_data.household_options;
        } else {
            householdOptions = baseData.household_options;
        }

        let householdOptionText = "Ingen";

        for (const item of householdOptions) {
            if (item.id === orderData.household_option_id) {
                householdOptionText = item.name;
                break;
            }
        }

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <Box
                    className='specification'
                    padding={'8px'}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>Hushållsbegränsning:</Box>
                    </Box>
                    <Button
                        size={'small'}
                        style={{textTransform:'none', minWidth: '100px'}}
                        variant='contained'
                        color={orderData.household_option_id === 0 ? 'secondary' : 'primary'}
                        onClick={(event) => { event.stopPropagation(); setVisibleModal('household_option') }}>
                            {householdOptionText}
                    </Button>
                </Box>
            </Box>
        );
    }

    // ===============================================================
    // === Render Count
    // ===============================================================

    const createSummaryCount = () => {

        return (
            <Box display={'flex'} flexDirection={'column'} marginTop={'8px'}>
                <Box>
                    <b>Antal Leads</b>
                </Box>
                {renderCountBase()}
                {renderCountRemovedByStopfiles()}
                {renderCountRemovedByHouseholdOption()}
                {renderCountTotal()}
            </Box>
        );
    }

    const renderCountBase = () => {

        let textElement = undefined;
        if (orderData.order_count !== undefined) {
            if (orderData.order_count.state === CountOrderData.STATE_READY && orderData.order_count.api_data !== undefined) {
                textElement = util_strings_format_with_spaces(orderData.order_count.api_data.counts.base);
            } else {
                textElement = (<CircularProgress color={'primary'} size={18} />);
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box>Urval:</Box>
                <Box>{textElement}</Box>
            </Box>
        );
    }

    const renderCountRemovedByStopfiles = () => {

        let textElement = undefined;
        if (orderData.order_count !== undefined) {
            if (orderData.order_count.state === CountOrderData.STATE_READY && orderData.order_count.api_data !== undefined) {
                textElement = util_strings_format_with_spaces(orderData.order_count.api_data.counts.removed_by_stopfiles);
            } else {
                textElement = (<CircularProgress color={'primary'} size={18} />);
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box>Uteslutna av stoppfiler:</Box>
                <Box>{textElement}</Box>
            </Box>
        );
    }

    const renderCountRemovedByHouseholdOption = () => {

        let textElement = undefined;
        if (orderData.order_count !== undefined) {
            if (orderData.order_count.state === CountOrderData.STATE_READY && orderData.order_count.api_data !== undefined) {
                textElement = util_strings_format_with_spaces(orderData.order_count.api_data.counts.removed_by_households);
            } else {
                textElement = (<CircularProgress color={'primary'} size={18} />);
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box>Uteslutna av hushållsbegränsning:</Box>
                <Box>{textElement}</Box>
            </Box>
        );
    }

    const renderCountTotal = () => {

        let textElement = undefined;
        if (orderData.order_count !== undefined) {
            if (orderData.order_count.state === CountOrderData.STATE_READY && orderData.order_count.api_data !== undefined) {
                textElement = util_strings_format_with_spaces(orderData.order_count.api_data.counts.final_count);
            } else {
                textElement = (<CircularProgress color={'primary'} size={18} />);
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box style={{fontSize: '20px'}}>Tillgängligt antal:</Box>
                <Box style={{fontSize: '20px', fontWeight: 'bold'}}>{textElement}</Box>
            </Box>
        );
    }

    // ===============================================================
    // === Render Limit
    // ===============================================================

    const createCountToOrder = () => {
        return (
            <Box display={'flex'} flexDirection={'column'} marginTop={'8px'}>
                <Box>
                    <b>Antal att beställa</b>
                </Box>
                {renderLimit()}
            </Box>
        );
    }

    const renderLimit = () => {

        let count = -1;
        if (
            orderData.order_count !== undefined
            && orderData.order_count.api_data !== undefined
        ) {
            count = orderData.order_count.api_data.counts.final_count;
        }

        let error = undefined;
        const limit = orderData.size_limit;
        if (limit != 0) {
            if (limit < 100) {
                error = 'Minsta beställning är 100';
            } else if (limit > 100000) {
                error = 'Största beställning är 100 000';
            } else if (count > -1 && count < limit) {
                error = 'Fler än tillgängligt antal.';
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box>Ange Antal:</Box>
                <Box display={'flex'} flexDirection={'row'}>
                    <Box marginLeft={'8px'} width={'150px'}>
                        <TextField
                            type={'number'}
                            variant="outlined"
                            size={'small'}
                            value={orderData.size_limit === 0 ? '' : orderData.size_limit}
                            onChange={(event) => actionOnChangeLimit(event)}
                            placeholder={''}
                            error={error !== undefined}
                            helperText={error}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    // ===============================================================
    // === Render Price
    // ===============================================================

    const createSummaryPrice = () => {

        if (!userData.isLoggedIn()) {
            return (
                <Box display={'flex'} flexDirection={'column'} marginTop={'8px'}>
                    <Box>
                        <b>Pris</b>
                    </Box>
                    <Box padding={'8px'}>
                        Logga in för att se pris.
                    </Box>
                </Box>
            )
        }

        return (
            <Box display={'flex'} flexDirection={'column'} marginTop={'8px'}>
                <Box>
                    <b>Pris</b>
                </Box>
                {renderBasePrice()}
                {renderPricePerLead()}
                {renderNumberOfLeads()}
                {renderPriceExclVat()}
                {renderPriceVat()}
                {renderPriceToPay()}
            </Box>
        );
    }

    const renderBasePrice = () => {

        let textElement = undefined;
        if (orderData.order_price !== undefined) {

            // Case 1: Price avalible and not zero.
            if (
                orderData.order_price.isReady()
                && orderData.order_price.api_data !== undefined
                && orderData.order_price.api_data.price_base > 0
            ) {
                textElement = util_strings_format_price_w_decimals(orderData.order_price.api_data.price_base) + ' kr';

            // Case 2: Price loading
            } else if (orderData.order_price.isWaiting()) {
                textElement = undefined;

            // Case 3: Price zero or unavaliable and not loading
            } else {
                return undefined;
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box>Grundpris:</Box>
                <Box>{textElement}</Box>
            </Box>
        );
    }

    const renderPricePerLead = () => {

        let textElement = undefined;
        if (orderData.order_price !== undefined) {
            if (orderData.order_price.isReady() && orderData.order_price.api_data !== undefined) {
                textElement = util_strings_format_price_w_decimals(orderData.order_price.api_data.price_per_item) + ' kr';
            } else if (orderData.order_price.isWaiting()) {
                textElement = undefined;
            }
        }

        const isExpanded = expandedItem === 'price_per_lead';
        const expandIcon = isExpanded ? <RemoveIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />

        let expandedContainerElement = undefined;
        if (isExpanded) {

            if (orderData.order_price !== undefined
                && orderData.order_price.isReady()
                && orderData.order_price.api_data !== undefined) {

                    const basePriceTypes = [ApiObjPriceObjectType.ID_BASE_PRICE_NIX_OPTION, ApiObjPriceObjectType.ID_BASE_PRICE_SOURCE];
                    const specLines = [];

                    let tempKey = 1;
                    for (const item of orderData.order_price.api_data.spec_items) {
                        if (basePriceTypes.includes(item.object_type_id)) {
                            continue;
                        }
                        specLines.push(
                            <Box key={tempKey} marginTop={'6px'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                <Box>{item.name}</Box>
                                <Box>{util_strings_format_price_w_decimals(item.getPriceFloat())} kr</Box>
                            </Box>
                        );
                        tempKey ++;
                    }
                    expandedContainerElement = (
                        <Box padding={'14px'}>{specLines}</Box>
                    );

            } else {
                expandedContainerElement = (
                    <Box padding={'14px'}><CircularProgress color={'primary'} size={18} /></Box>
                );
            }
        }

        const actionOnClick = () => {
            if (expandedItem === 'price_per_lead') {
                setExpandedItem('');
            } else {
                setExpandedItem('price_per_lead');
            }
        };

        return (
            <Box>
                <Box
                    className='specification'
                    padding={'8px'}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    onClick={actionOnClick}
                >
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>Pris per lead:</Box>
                        {expandIcon}
                    </Box>
                    <Box>{textElement}</Box>
                </Box>
                {expandedContainerElement}
            </Box>
        );
    }

    const renderNumberOfLeads = () => {
        let textElement = undefined;
        if (orderData.order_price !== undefined) {
            if (orderData.order_price.isReady() && orderData.order_price.api_data !== undefined) {
                textElement = util_strings_format_with_spaces(orderData.order_price.api_data.item_count);
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box>Antal leads:</Box>
                <Box>{textElement}</Box>
            </Box>
        );
    }

    const renderPriceExclVat = () => {
        let textElement = undefined;
        if (orderData.order_price !== undefined) {
            if (orderData.order_price.isReady() && orderData.order_price.api_data !== undefined) {
                textElement = util_strings_format_price_w_decimals(orderData.order_price.api_data.price_total_excl_vat) + ' kr';
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box>Pris totalt exkl moms:</Box>
                <Box>{textElement}</Box>
            </Box>
        );
    }

    const renderPriceVat = () => {
        let textElement = undefined;
        if (orderData.order_price !== undefined) {
            if (orderData.order_price.isReady() && orderData.order_price.api_data !== undefined) {
                textElement = util_strings_format_price_w_decimals(orderData.order_price.api_data.price_total_vat) + ' kr';
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box>Moms (25 %):</Box>
                <Box>{textElement}</Box>
            </Box>
        );
    }

    const renderPriceToPay = () => {
        let textElement = undefined;
        if (orderData.order_price !== undefined) {
            if (orderData.order_price.isReady() && orderData.order_price.api_data !== undefined) {
                textElement = util_strings_format_price_w_decimals(orderData.order_price.api_data.price_total) + ' kr';
            }
        }

        return (
            <Box
                className='specification'
                padding={'8px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Box style={{fontSize: '20px'}}>Att betala:</Box>
                <Box style={{fontSize: '20px', fontWeight: 'bold'}}>{textElement}</Box>
            </Box>
        );
    }

    // ===============================================================
    // === Modals
    // ===============================================================

    const tryRenderModalHouseholdOption = () : JSX.Element|undefined => {
        if (visibleModal === 'household_option') {
            return (
                <HouseholdOptionModal
                    baseData={baseData}
                    userData={userData}
                    householdOptionId={orderData.household_option_id}
                    onClose={actionModalCancel}
                    onChange={(newHouseholdOptionId) => { actionSethouseholdOption(newHouseholdOptionId) }}
                />
            );
        }
        return undefined;
    }

    // ===============================================================
    // === Render Main
    // ===============================================================

    return (
        <Box>
            <Paper>
                <Box padding={'14px'}>
                    {renderTitleBar()}
                    {createYourSelection()}
                    {createSummaryCount()}
                    {createCountToOrder()}
                    {createSummaryPrice()}
                    {tryRenderModalHouseholdOption()}
                </Box>
            </Paper>
        </Box>
    );
}
