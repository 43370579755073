import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjAdAccount } from '../../object/facebook/ApiObjAdAccount';

export const api_get_ad_accounts = () : Promise<Array<ApiObjAdAccount>> => {
    return new Promise<Array<ApiObjAdAccount>> ((resolve, reject) => {
        const url = API_URL+'/facebook/ad_account';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        Axios.get(
            url,
            config
        )
        .then((resp) => {
            const ret : Array<ApiObjAdAccount> = [];
            for (const item of resp.data) {
                ret.push(new ApiObjAdAccount(item));
            }
            return resolve(ret);

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}

