import { ApiObjBaseData } from "../../api/object/base_data/ApiObjBaseData";
import { ApiObjUserMy } from "../../api/object/user/ApiObjUserMy";
import { UtilArray } from "../../util/code/UtilArray";

export const clean_export_fields = (ids: Array<number>, baseData : ApiObjBaseData, userMy: undefined|ApiObjUserMy) : Array<number> => {
    const cleanIds = [];

    if (userMy !== undefined) {
        for (const id of ids) {
            const exportField = userMy.getExportField(id);
            if (exportField !== undefined) {
                cleanIds.push(id);
            }
        }
    } else {
        for (const id of ids) {
            const exportField = baseData.getExportField(id);
            if (exportField !== undefined) {
                cleanIds.push(id);
            }
        }
    }
    
    return UtilArray.createUniqueSortedIntArray(cleanIds);
}