import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjTargetGroup } from '../../object/target_group/ApiObjTargetGroup';

export const api_get_target_group = (id: number) : Promise<ApiObjTargetGroup> => {
    return new Promise<ApiObjTargetGroup> ((resolve, reject) => {
        const url = API_URL+'/target_group/'+id;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            return resolve(new ApiObjTargetGroup(resp.data));

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}