import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfile } from '../../object/stopfile/ApiObjStopfile';

export const api_get_stopfile = (stopfileId: number) : Promise<ApiObjStopfile> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/stopfile/'+stopfileId;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            resolve(new ApiObjStopfile(resp.data));
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}