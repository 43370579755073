import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export interface UpdateMyUserData {
    first_name?: string,
    last_name?: string,
    company_name?: string,
    company_number?: string,
    address?: string,
    zipcode?: string,
    city?: string,
    phone_number?: string,
}

export const api_update_my_user_details = (data: UpdateMyUserData) : Promise<{success: boolean}> => {

    return new Promise <{success: boolean}> ((resolve, reject) => {

        const url = API_URL+'/my_user/details'
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.patch(
            url,
            data,
            config
        )
        .then((resp) => {
            resolve({success: true});
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}