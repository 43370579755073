import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { OrderData } from './OrderData';
import { UtilArray } from '../../util/code/UtilArray';
import { api_create_export } from '../../api/endpoint/export/api_create_export';
import { api_get_export } from '../../api/endpoint/export/api_get_export';
import { UserData } from '../user/UserData';
import { UserManager } from '../user/UserManager';
import { MapAreaCircle } from '../map/MapAreaCircle';
import { MapAreaPolygon } from '../map/MapAreaPolygon';
import { CountOrderManager } from '../count/order/CountOrderManager';
import { CountFilterManager } from '../count/filter/CountFilterManager';
import { CountOrderData } from '../count/order/CountOrderData';
import { CountFilterDataItem } from '../count/filter/CountFilterDataItem';
import { ApiObjTargetGroup } from '../../api/object/target_group/ApiObjTargetGroup';
import { ApiObjStopfile } from '../../api/object/stopfile/ApiObjStopfile';
import moment from 'moment';
import { clean_source } from '../clean_objects/clean_source';
import { clean_filters } from '../clean_objects/clean_filters';
import { clean_export_fields } from '../clean_objects/clean_export_fields';
import { ApiObjLinkOrderData } from '../../api/object/link/ApiObjLinkOrderData';
import { ApiObjExport } from '../../api/object/export/ApiObjExport';
import { OrderPriceManager } from '../order_price/OrderPriceManager';
import { OrderPriceData } from '../order_price/OrderPriceData';
import { FilterTreeMini } from '../filtertree/FilterTreeMini';

export class OrderManager {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    private baseData : undefined | ApiObjBaseData;
    private callbackNewOrderData: undefined | ((orderData: OrderData) => void);

    private orderData: OrderData;
    private childDataCountOrder: undefined|CountOrderData = undefined;
    private childDataCountFilter: Array<CountFilterDataItem> = [];
    private childDataOrderPrice: undefined|OrderPriceData = undefined;
    private filterTreeMini: undefined|FilterTreeMini;

    private userData : undefined | UserData;
    private userManager: undefined | UserManager;

    private countOrderManager: CountOrderManager;
    private countFilterManager: CountFilterManager|undefined;
    private orderPriceManager: OrderPriceManager;

    // ==========================================================================
    // === Create
    // ==========================================================================

    constructor() {
        this.orderData = OrderData.createNew();
        this.countOrderManager = new CountOrderManager(this.callbackNewCountOrderData);
        this.orderPriceManager = new OrderPriceManager(this.callbackOrderPriceData);
    }

    public parentSetUserManager = (userManager: UserManager) => {
        this.userManager = userManager;
    }

    public parentSetCallback = (callback : (orderData: OrderData) => void) => {
        this.callbackNewOrderData = callback;
    }

    public parentSetBaseData = (baseData: ApiObjBaseData) => {
        this.baseData = baseData;
        if (this.countFilterManager === undefined) {
            this.countFilterManager = new CountFilterManager(baseData, this.callbackNewCountFilerData);
        }
    }

    public parentSetUserData = (userData: UserData) => {

        const subscriptionJustDetected = userData.api_data !== undefined
            && userData.api_data.main_subscription.type !== 0
            && this.userData !== undefined
            && this.userData.api_data === undefined;

        this.userData = userData;

        if (subscriptionJustDetected) {
            this.orderData.payment_method = OrderData.PAYMENT_METHOD_SUBSCRIPTION;
            this.makeCallback();
        }
        this.pokePriceManager();
    }

    // ==========================================================================
    // === Sub Managers callback
    // ==========================================================================

    public callbackNewCountOrderData = (newData: undefined|CountOrderData) => {
        this.childDataCountOrder = newData;
        this.pokePriceManager();
        this.makeCallback();
    }

    public callbackNewCountFilerData = (newData: Array<CountFilterDataItem>) => {
        this.childDataCountFilter = newData;
        this.makeCallback();
    }

    public callbackOrderPriceData = (newData: OrderPriceData) => {
        this.childDataOrderPrice = newData;
        this.makeCallback();
    }

    // ==========================================================================
    // === Nix Option
    // ==========================================================================

    public actionSetNixOptionId = (nixOptionId: number) => {
        if (!this.orderData.canEditOrder()) {
            return;
        }
        this.orderData.nix_option_id = nixOptionId;
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Populate from Target group
    // ==========================================================================

    public actionPopulateFromTargetGroup = (targetGroup: ApiObjTargetGroup, userStopfiles: Array<ApiObjStopfile>) => {

        if (!this.orderData.canEditOrder()) {
            return;
        }
        
        if (this.baseData === undefined) {
            throw new Error("Base data undefined, very unexpected");
        }

        const userApiData = (this.userData === undefined) ? undefined : this.userData.api_data;

        this.orderData.source_id = clean_source(targetGroup.source_id, userApiData);
        this.orderData.nix_option_id = targetGroup.nix_option_id;
        this.orderData.filter_ids_selected = clean_filters(targetGroup.filter_ids, this.baseData, userApiData);
        
        const mapAreas = [];
        for (const item of targetGroup.map_areas) {
            if (item instanceof MapAreaCircle) {
                mapAreas.push(MapAreaCircle.createClone(item));
            } else if (item instanceof MapAreaPolygon) {
                mapAreas.push(MapAreaPolygon.createClone(item));
            } else {
                throw new Error("Unexpected map area type.");
            }
        }
        this.orderData.map_areas = mapAreas;
        this.orderData.export_field_ids = clean_export_fields(targetGroup.export_field_ids, this.baseData, userApiData);

        const now = moment();
        const stopfileIds = [];

        for (const stopfile of userStopfiles) {

            let isValid = false;

            if (targetGroup.stopfile_ids.includes(stopfile.id)) {
                isValid = true;
            } else if (targetGroup.stopfile_days > 0) {
                const createdAt = moment(stopfile.created_at);
                const diffDays = now.diff(createdAt, 'days');

                if (diffDays <= targetGroup.stopfile_days) {
                    isValid = true;
                }
            }
            if (isValid) {
                stopfileIds.push(stopfile.id);
            }
        }
        this.orderData.stopfile_ids = UtilArray.createUniqueSortedIntArray(stopfileIds);
        this.orderData.household_option_id = targetGroup.household_option_id;

        if (userApiData !== undefined && userApiData.hasSubscription()) {
            this.orderData.payment_method = OrderData.PAYMENT_METHOD_SUBSCRIPTION;
        }

        this.filterTreeMini = new FilterTreeMini(this.orderData.filter_ids_selected, this.baseData);
        
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Populate from Link data
    // ==========================================================================

    public actionPopulateFromLinkData = (data: ApiObjLinkOrderData, userStopfiles: Array<ApiObjStopfile>) => {

        if (this.baseData === undefined) {
            throw new Error("Base data undefined, very unexpected");
        }

        this.orderData = OrderData.createNew();
        
        const userApiData = (this.userData === undefined) ? undefined : this.userData.api_data;

        this.orderData.source_id = clean_source(data.source_id, userApiData);
        this.orderData.nix_option_id = data.nix_option_id;
        this.orderData.filter_ids_selected = clean_filters(data.filter_ids, this.baseData, userApiData);
        
        const mapAreas = [];
        for (const item of data.map_areas) {
            if (item instanceof MapAreaCircle) {
                mapAreas.push(MapAreaCircle.createClone(item));
            } else if (item instanceof MapAreaPolygon) {
                mapAreas.push(MapAreaPolygon.createClone(item));
            } else {
                throw new Error("Unexpected map area type.");
            }
        }
        this.orderData.map_areas = mapAreas;
        this.orderData.export_field_ids = clean_export_fields(data.export_field_ids, this.baseData, userApiData);

        const now = moment();
        const stopfileIds = [];

        for (const stopfile of userStopfiles) {

            let isValid = false;

            if (data.stopfile_ids.includes(stopfile.id)) {
                isValid = true;
            } else if (data.stopfile_days > 0) {
                const createdAt = moment(stopfile.created_at);
                const diffDays = now.diff(createdAt, 'days');

                if (diffDays <= data.stopfile_days) {
                    isValid = true;
                }
            }
            if (isValid) {
                stopfileIds.push(stopfile.id);
            }
        }
        this.orderData.stopfile_ids = UtilArray.createUniqueSortedIntArray(stopfileIds);
        this.orderData.household_option_id = data.household_option_id;

        if (userApiData !== undefined && userApiData.hasSubscription()) {
            this.orderData.payment_method = OrderData.PAYMENT_METHOD_SUBSCRIPTION;
        }

        this.filterTreeMini = new FilterTreeMini(this.orderData.filter_ids_selected, this.baseData);
        
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Populate from Export
    // ==========================================================================

    public actionPopulateFromExport = (data: ApiObjExport, userStopfiles: Array<ApiObjStopfile>) => {
        
        if (this.baseData === undefined) {
            throw new Error("Base data undefined, very unexpected");
        }

        this.orderData = OrderData.createNew();
        
        const userApiData = (this.userData === undefined) ? undefined : this.userData.api_data;

        this.orderData.source_id = clean_source(data.params.source_id, userApiData);
        this.orderData.nix_option_id = data.params.nix_option_id;
        this.orderData.filter_ids_selected = clean_filters(data.params.filter_ids, this.baseData, userApiData);
        
        const mapAreas = [];
        for (const item of data.params.map_areas) {
            if (item instanceof MapAreaCircle) {
                mapAreas.push(MapAreaCircle.createClone(item));
            } else if (item instanceof MapAreaPolygon) {
                mapAreas.push(MapAreaPolygon.createClone(item));
            } else {
                throw new Error("Unexpected map area type.");
            }
        }
        this.orderData.map_areas = mapAreas;
        this.orderData.export_field_ids = clean_export_fields(data.params.export_field_ids, this.baseData, userApiData);

        const stopfileIds = [];
        for (const stopfile of userStopfiles) {
            if (data.params.stopfile_ids.includes(stopfile.id)) {
                stopfileIds.push(stopfile.id);
            }
        }
        this.orderData.stopfile_ids = UtilArray.createUniqueSortedIntArray(stopfileIds);
        this.orderData.household_option_id = data.params.household_option_id;
        this.orderData.size_limit = data.params.size_limit;

        if (
            userApiData !== undefined
            && userApiData.hasSubscription()
            && data.payment_method === OrderData.PAYMENT_METHOD_SUBSCRIPTION
        ) {
            this.orderData.payment_method = OrderData.PAYMENT_METHOD_SUBSCRIPTION;
        }

        this.filterTreeMini = new FilterTreeMini(this.orderData.filter_ids_selected, this.baseData);
        
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Source
    // ==========================================================================

    public actionSetSourceId = (sourceId: number) => {
        if (!this.orderData.canEditOrder()) {
            return;
        }
        this.orderData.source_id = sourceId;
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Filters
    // ==========================================================================

    public actionSetFiltersSelected = (filterIds: Array<number>) => {

        if (this.baseData === undefined) {
            throw new Error("Base data undefined, very unexpected");
        }

        if (!this.orderData.canEditOrder()) {
            return;
        }

        this.orderData.filter_ids_selected = UtilArray.createUniqueSortedIntArray(filterIds);

        this.filterTreeMini = new FilterTreeMini(this.orderData.filter_ids_selected, this.baseData);

        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    public actionSetFiltersVisible = (filterIds: Array<number>) => {

        if (this.baseData === undefined) {
            throw new Error("Base data undefined, very unexpected");
        }

        if (!this.orderData.canEditOrder()) {
            return;
        }

        this.orderData.filter_ids_visible = UtilArray.createUniqueSortedIntArray(filterIds);

        this.pokeCountManagers();
        this.makeCallback();
    }

    public actionRemoveFilterAndChildren = (filterId: number) => {
        
        if (this.baseData === undefined) {
            throw new Error("Base data undefined, very unexpected");
        }

        const isIdInPathToRoot = (curFilterId: number, idToFind: number) : boolean => {

            if (this.baseData === undefined) {
                throw new Error("Base data undefined, very unexpected");
            }
            if (curFilterId === idToFind) {
                return true; // Id found.
            }
            const tempFilter = this.baseData.getFilter(curFilterId);
            if (tempFilter === undefined || tempFilter.parent_id === -1) {
                return false;   // Root found.
            }
            if (tempFilter.parent_id === idToFind) {
                return true;    // Id found.
            }
            return isIdInPathToRoot(tempFilter.parent_id, idToFind); // Need to check more.
        }

        const idsToKeep = [];
        
        for (const idToCheck of this.orderData.filter_ids_selected) {
            if (isIdInPathToRoot(idToCheck, filterId)) {
                continue;
            }
            idsToKeep.push(idToCheck);
        }
        
        this.orderData.filter_ids_selected = UtilArray.createUniqueSortedIntArray(idsToKeep);
        this.filterTreeMini = new FilterTreeMini(this.orderData.filter_ids_selected, this.baseData);

        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Map area
    // ==========================================================================

    public actionAddMapArea = (area: MapAreaCircle|MapAreaPolygon) : void => {

        if (!this.orderData.canEditOrder()) {
            return;
        }

        let newId = 1;
        for (const item of this.orderData.map_areas) {
            if (item.id >= newId) {
                newId = item.id + 1;
            }
        }
        let newItem = undefined;
        if (area instanceof MapAreaCircle) {
            newItem = MapAreaCircle.createClone(area);
            newItem.id = newId;
        } else if (area instanceof MapAreaPolygon) {
            newItem = MapAreaPolygon.createClone(area);
            newItem.id = newId;
        }

        if (newItem === undefined) {
            return
        }

        this.orderData.map_areas.push(newItem);
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    public actionRemoveMapArea = (areaId: number) : void => {

        if (!this.orderData.canEditOrder()) {
            return;
        }

        for (let k = 0; k < this.orderData.map_areas.length; k ++) {
            if (this.orderData.map_areas[k].id === areaId) {
                this.orderData.map_areas.splice(k, 1);
                break;
            }
        }
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Export fields
    // ==========================================================================

    public actionAddExportField = (exportFieldId: number) => {

        if (!this.orderData.canEditOrder()) {
            return;
        }

        let newArray = [...this.orderData.export_field_ids, exportFieldId];
        newArray = UtilArray.createUniqueSortedIntArray(newArray);
        this.orderData.export_field_ids = newArray;
        this.pokePriceManager();
        this.makeCallback();
    }

    public actionRemoveExportField = (exportFieldId: number) => {

        if (!this.orderData.canEditOrder()) {
            return;
        }

        const newArray = UtilArray.removeArrayFromArray([exportFieldId], this.orderData.export_field_ids);
        this.orderData.export_field_ids = newArray;
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Stopfiles
    // ==========================================================================

    public actionAddStopfiles = (stopFileIds: Array<number>) => {

        if (!this.orderData.canEditOrder()) {
            return;
        }

        let newArray = [...this.orderData.stopfile_ids, ...stopFileIds];
        newArray = UtilArray.createUniqueSortedIntArray(newArray);
        this.orderData.stopfile_ids = newArray;
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    public actionRemoveStopfiles = (stopFileIds: Array<number>) => {

        if (!this.orderData.canEditOrder()) {
            return;
        }

        const newArray = UtilArray.removeArrayFromArray(stopFileIds, this.orderData.stopfile_ids);
        this.orderData.stopfile_ids = newArray;
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Household option
    // ==========================================================================

    public actionSetHouseholdOption = (householdOptionId: number) => {

        if (!this.orderData.canEditOrder()) {
            return;
        }
        this.orderData.household_option_id = householdOptionId;
        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Size limit
    // ==========================================================================

    public actionSetSizeLimit = (sizeLimit: number) => {

        if (!this.orderData.canEditOrder()) {
            return;
        }

        this.orderData.size_limit = sizeLimit;
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Filename
    // ==========================================================================

    public actionSetFilename = (filename: string) => {

        if (!this.orderData.canEditOrder()) {
            return;
        }
        this.orderData.filename = filename;
        this.makeCallback();
    }

    // ==========================================================================
    // === Payment method
    // ==========================================================================

    public actionSetPaymentMethod = (paymentMethod: number) => {

        if (!this.orderData.canEditOrder() || ![0,1,2,3].includes(paymentMethod)) {
            return;
        }
        this.orderData.payment_method = paymentMethod;
        this.makeCallback();
    }

    // ==========================================================================
    // === Terms
    // ==========================================================================

    public actionToggleTermsAccepted = () => {

        if (!this.orderData.canEditOrder()) {
            return;
        }
        this.orderData.terms_accepted = !this.orderData.terms_accepted;
        this.makeCallback();
    }

    // ==========================================================================
    // === Make purchase
    // ==========================================================================

    public actionPurchaseStart = () => {

        if (!this.orderData.canMakePurchase(this.userData)) {
            return;
        }

        this.orderData.purchase_state = OrderData.PURCHASE_STATE_2_CREATING_EXPORT;

        const params = {
            nixOptionId: this.orderData.nix_option_id,
            sourceId: this.orderData.source_id,
            filterIds: this.orderData.filter_ids_selected,
            mapAreas: this.orderData.map_areas,
            stopfileIds: this.orderData.stopfile_ids,
            householdOptionId: this.orderData.household_option_id,
            leadsCount: this.orderData.getCountToOrder(),
            sizeLimit: this.orderData.size_limit,
            exportFieldIds: this.orderData.export_field_ids,
            name: this.orderData.filename,
            paymentMethod: this.orderData.payment_method
        };

        api_create_export(params)
            
            .then((resp) => {
                this.handleCreateExportResponse(resp);
                this.makeCallback();
                if (this.userManager !== undefined) {
                    this.userManager.actionReloadUserData();
                }

            }).catch(() => {
                this.orderData.purchase_state = OrderData.PURCHASE_STATE_3_EXPORT_FAILED;
                console.error("Could not create new export, unexpected.");      // TODO ERIK hantera fel bättre spara och visa i UI.
                this.makeCallback();
            });

        this.makeCallback();
    }

    private handleCreateExportResponse = (resp: any) => {

        const tempExportId = parseInt(resp.export_id);
        this.orderData.purchase_export_id = tempExportId;
        
        if (resp.stripe_client_secret !== undefined) {
            this.orderData.purchase_stripe_client_secret = resp.stripe_client_secret;
            this.orderData.purchase_stripe_public_key = resp.stripe_public_key;
        }

        api_get_export(tempExportId)

            .then((resp) => {
                switch (resp.state) {
                    case 5:
                    case 7:
                        this.orderData.purchase_state = OrderData.PURCHASE_STATE_6_SUCCESS;
                        break;
                    case 2:
                        this.orderData.purchase_state = OrderData.PURCHASE_STATE_4_WAITING_FOR_STRIPE_INPUT;
                        break;
                }
                this.makeCallback();

            }).catch(() => {
                this.orderData.purchase_state = OrderData.PURCHASE_STATE_3_EXPORT_FAILED;
                console.error("Could not find new export, unexpected. Export id: "+tempExportId);
                this.makeCallback();
            });
            
        this.makeCallback();
    }

    // ==========================================================================
    // === Purchase state
    // ==========================================================================

    public actionResetPurchaseState = () => {
        this.orderData.purchase_state = OrderData.PURCHASE_STATE_1_NOT_STARTED;
        this.orderData.purchase_export_id = -1;
        this.orderData.purchase_stripe_client_secret = '';
        this.orderData.purchase_stripe_public_key = '';
        this.makeCallback();
    }

    public actionPurchaseStripeSuccess = () => {
        this.orderData.purchase_state = OrderData.PURCHASE_STATE_6_SUCCESS;
        this.makeCallback();
    }

    public actionPurchaseStripeFailed = () => {
        this.orderData.purchase_state = OrderData.PURCHASE_STATE_5_STRIPE_FAILED;
        this.makeCallback();
    }

    // ==========================================================================
    // === Order reset
    // ==========================================================================

    public actionOrderReset = () => {

        if (this.baseData === undefined) {
            throw new Error("Base data undefined, very unexpected");
        }

        this.orderData = OrderData.createNew();

        if (
            this.userData !== undefined
            && this.userData.api_data !== undefined
            && this.userData.api_data.hasSubscription()
        ) {
            this.orderData.payment_method = OrderData.PAYMENT_METHOD_SUBSCRIPTION;
        }

        this.filterTreeMini = new FilterTreeMini(this.orderData.filter_ids_selected, this.baseData);

        this.pokeCountManagers();
        this.pokePriceManager();
        this.makeCallback();
    }

    // ==========================================================================
    // === Poke sub managers
    // ==========================================================================

    private pokeCountManagers = () => {

        const tempMapAreas = [];
        for (const item of this.orderData.map_areas) {
            if (item instanceof MapAreaCircle) {
                tempMapAreas.push(MapAreaCircle.createClone(item));
            } else if (item instanceof MapAreaPolygon) {
                tempMapAreas.push(MapAreaPolygon.createClone(item));
            } else {
                throw new Error("Unknown type");
            }
        }

        if (this.countFilterManager !== undefined) {
            const newParamsFilter = {
                nix_option_id: this.orderData.nix_option_id,
                source_id: this.orderData.source_id,
                filter_ids_selected: [...this.orderData.filter_ids_selected],
                filter_ids_to_check: [...this.orderData.filter_ids_visible],
                map_areas: tempMapAreas,
            }
            this.countFilterManager.actionNewParams(newParamsFilter);
        }

        const newParamsOrder = {
            nix_option_id: this.orderData.nix_option_id,
            source_id: this.orderData.source_id,
            filter_ids: [...this.orderData.filter_ids_selected],
            map_areas: tempMapAreas,
            stopfile_ids: [...this.orderData.stopfile_ids],
            household_option_id: this.orderData.household_option_id
        };

        this.countOrderManager.actionNewParams(newParamsOrder);
    }

    private pokePriceManager = () => {

        let paramLimit = -1;
        if (this.orderData.size_limit > 0) {
            paramLimit = this.orderData.size_limit;
        }

        let actualCount = -1;
        if (
            this.childDataCountOrder !== undefined
            && this.childDataCountOrder.api_data !== undefined
        ) {
            actualCount = this.childDataCountOrder.api_data.counts.final_count;
        }

        let countForPriceManager = - 1;

        if (paramLimit != -1 && actualCount != -1) {
            countForPriceManager = (paramLimit < actualCount) ? paramLimit : actualCount;
        } else if (paramLimit != -1) {
            countForPriceManager = paramLimit;
        } else if (actualCount != -1) {
            countForPriceManager = actualCount;
        }

        const hasUser = this.userData !== undefined
            && this.userData.isLoggedIn()
            && this.userData.api_data !== undefined;

        const newParamsPrice = {
            has_user: hasUser,
            item_count: countForPriceManager,
            nix_option_id: this.orderData.nix_option_id,
            source_id: this.orderData.source_id,
            filter_ids: [...this.orderData.filter_ids_selected],
            uses_map: this.orderData.map_areas.length > 0,
            export_field_ids: [...this.orderData.export_field_ids],
            uses_stopfiles: this.orderData.stopfile_ids.length > 0,
            household_option_id: this.orderData.household_option_id
        }
        this.orderPriceManager.actionNewParams(newParamsPrice);
    }

    // ==========================================================================
    // === Make callback
    // ==========================================================================

    private makeCallback = () : void => {

        // Truth is stored in childData variables and not orderData for child components.
        this.orderData.order_count = this.childDataCountOrder;
        this.orderData.filter_count = this.childDataCountFilter;
        this.orderData.order_price = this.childDataOrderPrice;
        this.orderData.filter_tree_mini = this.filterTreeMini;

        const orderDataClone = OrderData.createClone(this.orderData);
        if (this.callbackNewOrderData !== undefined) {
            this.callbackNewOrderData(orderDataClone);
        }
    }
}
