import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjLinkOrderData } from '../../object/link/ApiObjLinkOrderData';

export const api_get_link_order_data = (code: string) : Promise<ApiObjLinkOrderData> => {
    return new Promise<ApiObjLinkOrderData> ((resolve, reject) => {
        const url = API_URL+'/link/order_data/'+code;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            return resolve(new ApiObjLinkOrderData(resp.data));

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}