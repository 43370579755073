import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_order_post_step_1 = (populateFileId: number, columnTypes: Array<number>, matchMode: number) : Promise<ApiObjPopulateFile> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/populate_file/'+populateFileId+'/order_step_1';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        const body = {
            column_types: columnTypes,
            match_mode: matchMode
        }
        Axios.post(
            url,
            body,
            config
        )
        .then((resp) => {
            resolve(new ApiObjPopulateFile(resp.data));
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}