import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_change_my_user_password = (currentPassword: string, newPassword: string) : Promise<{success: boolean}> => {

    return new Promise <{success: boolean}> ((resolve, reject) => {

        const url = API_URL+'/my_user/password/change'
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        const data = {
            old_password: currentPassword,
            new_password: newPassword
        };
        Axios.put(
            url,
            data,
            config
        )
        .then((resp) => {
            resolve({success: true});
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}