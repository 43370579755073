import { ApiObjExportField } from '../../api/object/export/ApiObjExportField';
import { ApiObjFilter } from '../../api/object/filter/ApiObjFilter';
import { ApiObjUserMy } from '../../api/object/user/ApiObjUserMy';

/**
 * Holds data and state related to the current logged in user.
 * Passed to all screens which needs user data.
 */
export class UserData {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public static readonly STATE_INITIAL = 1;
    public static readonly STATE_TRY_FETCHING_USER = 2;
    public static readonly STATE_LOGGING_IN = 3;
    public static readonly STATE_LOGIN_FAILED = 4;
    public static readonly STATE_LOGGING_OUT = 5;
    public static readonly STATE_UPDATING_USER = 6;
    public static readonly STATE_UPDATE_USER_FAILED = 7;
    public static readonly STATE_READY_LOGGED_OUT = 8;
    public static readonly STATE_READY_LOGGED_IN = 9;

    private static readonly STATES_IDLE = [
        UserData.STATE_LOGIN_FAILED,
        UserData.STATE_UPDATE_USER_FAILED,
        UserData.STATE_READY_LOGGED_IN,
        UserData.STATE_READY_LOGGED_OUT
    ];

    private static readonly STATES_LOGGED_IN = [
        UserData.STATE_LOGGING_OUT,
        UserData.STATE_UPDATING_USER,
        UserData.STATE_UPDATE_USER_FAILED,
        UserData.STATE_READY_LOGGED_IN
    ];

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public state: number;
    public lastErrorMessage: string;
    public api_data: undefined|ApiObjUserMy;

    // ==========================================================================
    // === Create
    // ==========================================================================

    public static createNew = () : UserData => {
        return new UserData(undefined);
    }

    public static createClone = (original: UserData) : UserData => {
        return new UserData(original);
    }

    private constructor(old : UserData|undefined = undefined) {

        if (old === undefined) {
            this.state = UserData.STATE_INITIAL;
            this.lastErrorMessage = '';
            this.api_data = undefined;
        } else {
            this.state = old.state;
            this.lastErrorMessage = old.lastErrorMessage;
            this.api_data = old.api_data;   // Api data is imutable no need to deep copy.
        }
    }

    // ==========================================================================
    // === Helpers
    // ==========================================================================

    /**
     * Data is ready to be used, nothing is loading, but user might not be logged in.
     * 
     * ApiUserData: Maybe
     * Actions: Yes.
     */
    public isIdle = () : boolean => {
        return UserData.STATES_IDLE.includes(this.state);
    }

    /**
     * User is currently logged in. Loading might be in progress.
     * 
     * ApiUserData: Yes
     * Actions: Maybe
     */
    public isLoggedIn = () : boolean => {
        return UserData.STATES_LOGGED_IN.includes(this.state);
    }

    public isLoggedInAndAdmin = () : boolean => {
        return this.isLoggedIn()
            && this.api_data !== undefined
            && this.api_data.is_admin
    }

    
    public isFilterEnabled = (filter: ApiObjFilter) : boolean => {
        if (!filter.is_premium) {
            return true;
        }
        return this.isLoggedIn()
            && this.api_data !== undefined
            && this.api_data.enabled_filter_ids.includes(filter.id);
    }
}