import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export const api_admin_user_transfer_stopfile = (currentUserId: number, stopfileId: number, newUserId: number) : Promise<boolean> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/admin/user/'+currentUserId+'/stopfile/'+stopfileId+'/transfer';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        const payload = {
            new_user_id: newUserId
        }
        Axios.post(
            url,
            payload,
            config
        )
        .then((resp) => {
            resolve(true);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}