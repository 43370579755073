export function helper_group_filters (filterIds, filterMap) {
    
    const groupMap = new Map();

    for (const filterId of filterIds) {

        const filter = filterMap.get(filterId);
        if (filter === undefined) {
            console.log("Filter not found. filterId: "+filterId);
            continue;
        }

        const topParent = getTopParent(filterId, filterMap);
        if (topParent === undefined) {
            console.log("Filter top parent not found. filterId: "+filterId);
            continue;
        }
        let group = groupMap.get(topParent.id);
        if (group === undefined) {
            group = {
                id: topParent.id,
                name: topParent.name,
                sort_order: topParent.sort_order,
                children: [],
            }
            groupMap.set(topParent.id, group);
        }
        group.children.push({
            id: filter.id,
            name: filter.name,
            sort_order: filter.sort_order
        });
    }

    const ret = [];
    for (const item of groupMap.values()) {
        item.children.sort((a, b) => (a.sort_order - b.sort_order));
        ret.push(item);
    }
    ret.sort((a, b) => (a.sort_order - b.sort_order));
    return ret;
}

const getTopParent = (filterId, filterMap) => {
    const filter = filterMap.get(filterId);
    if (filter === undefined) {
        return undefined;
    }
    if (filter.parent_id == -1) {
        return filter;
    }
    return getTopParent(filter.parent_id, filterMap);
}