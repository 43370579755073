import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_delete_ad_account = (id: number) : Promise<void> => {
    return new Promise<void> ((resolve, reject) => {
        const url = API_URL+'/facebook/ad_account/'+id;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        
        Axios.delete(
            url,
            config
        )
        .then((resp) => {
            return resolve();

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}

