export class ApiObjFilter {

    public readonly id : number;
    public readonly parent_id : number;
    public readonly filter_select_group : number;
    public readonly name : string;
    public readonly value_column : number;
    public readonly value_1 : number;
    public readonly value_2 : number;
    public readonly is_selectable : boolean;
    public readonly is_premium : boolean;
    public readonly sort_order : number;
    public readonly counts : {n1: number, n2: number, n3: number, n4 : number, n5 : number, n6 : number, n7 : number, n8 : number};
    public readonly children : Array<ApiObjFilter>;

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.parent_id = parseInt(data.parent_id);
        this.filter_select_group = parseInt(data.filter_select_group);
        this.name = data.name;
        this.value_column = data.value_column;
        this.value_1 = parseInt(data.value_1);
        this.value_2 = parseInt(data.value_2);
        this.is_selectable = parseInt(data.is_selectable) === 1;
        this.is_premium = parseInt(data.is_premium) === 1;
        this.sort_order = parseInt(data.sort_order);
        this.counts = {
            n1: parseInt(data.counts.n1),
            n2: parseInt(data.counts.n2),
            n3: parseInt(data.counts.n3),
            n4: parseInt(data.counts.n4),
            n5: parseInt(data.counts.n5),
            n6: parseInt(data.counts.n6),
            n7: parseInt(data.counts.n7),
            n8: parseInt(data.counts.n8),
        }
        this.children = [];
        for (const child of data.children) {
            this.children.push(new ApiObjFilter(child));
        }
    }

    getCount(nixOptionId: number) : number {
        switch(nixOptionId) {
            case 1: return this.counts.n1;
            case 2: return this.counts.n2;
            case 3: return this.counts.n3;
            case 4: return this.counts.n4;
            case 5: return this.counts.n5;
            case 6: return this.counts.n6;
            case 7: return this.counts.n7;
            case 8: return this.counts.n8;
            default: return 0;
        }
    }
}