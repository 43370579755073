import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';
import { ApiObjTargetGroup } from '../../../../object/target_group/ApiObjTargetGroup';

export const api_admin_user_get_target_groups = (userId: number) : Promise<Array<ApiObjTargetGroup>> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/admin/user/'+userId+'/target_group';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            const ret = [];
            for (const item of resp.data) {
                ret.push(new ApiObjTargetGroup(item));
            }
            resolve(ret);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}