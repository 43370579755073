import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_get_all = () : Promise<Array<ApiObjPopulateFile>> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/populate_file';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            const ret = [];
            for (const item of resp.data) {
                ret.push(new ApiObjPopulateFile(item));
            }
            resolve(ret);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}