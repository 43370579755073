import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { ApiObjFilter } from '../../api/object/filter/ApiObjFilter';
import { TargetGroupEditData } from './TargetGroupEditData';
import { UtilArray } from '../../util/code/UtilArray';
import { MapAreaCircle } from '../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../data_layer/map/MapAreaPolygon';
import { ApiObjTargetGroup } from '../../api/object/target_group/ApiObjTargetGroup';
import { CountOrderManager } from '../count/order/CountOrderManager';
import { CountOrderData } from '../count/order/CountOrderData';
import { CountFilterManager } from '../count/filter/CountFilterManager';
import { CountFilterDataItem } from '../count/filter/CountFilterDataItem';
import { api_update_target_group } from '../../api/endpoint/target_group/api_update_target_group';
import { ApiObjStopfile } from '../../api/object/stopfile/ApiObjStopfile';
import { ApiObjUserMy } from '../../api/object/user/ApiObjUserMy';
import { api_update_target_group_count } from '../../api/endpoint/target_group/api_update_target_group_count';

export class TargetGroupEditManager {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    private userMy : ApiObjUserMy;
    private baseData : ApiObjBaseData;
    private apiTargetGroup: ApiObjTargetGroup;
    private apiObjStopfiles: Array<ApiObjStopfile>;
    private targetGroupData: TargetGroupEditData;
    
    private callbackNewData: undefined | ((targetGroupData: TargetGroupEditData) => void);

    private countOrderManager: CountOrderManager;
    private countFilterManager: CountFilterManager;

    // ==========================================================================
    // === Create
    // ==========================================================================

    constructor(userMy: ApiObjUserMy, baseData: ApiObjBaseData, apiTargetGroup: ApiObjTargetGroup, apiObjStopfiles: Array<ApiObjStopfile>) {
        this.userMy = userMy;
        this.baseData = baseData;
        this.apiTargetGroup = apiTargetGroup;
        this.apiObjStopfiles = apiObjStopfiles;
        this.targetGroupData = TargetGroupEditData.createFromApiObj(apiTargetGroup, baseData, apiObjStopfiles, userMy);
        this.countOrderManager = new CountOrderManager(this.callbackNewCountOrderData);
        this.countFilterManager = new CountFilterManager(baseData, this.callbackNewCountFilerData);
        this.tryUpdateAllCounts();
    }

    public parentSetCallback = (callback : (targetGroupData: TargetGroupEditData) => void) => {
        this.callbackNewData = callback;
        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    // ==========================================================================
    // === Count Manager callback
    // ==========================================================================

    public callbackNewCountOrderData = (newData: undefined|CountOrderData) => {
        this.targetGroupData.order_count = newData;
        this.trySaveCount();
        this.makeCallback();
    }

    public callbackNewCountFilerData = (newData: Array<CountFilterDataItem>) => {
        this.targetGroupData.filter_count = newData;
        this.makeCallback();
    }

    // ==========================================================================
    // === Name
    // ==========================================================================

    public actionSetName = (newName: string) => {
        this.targetGroupData.name = newName;
        this.makeCallback();
    }

    // ==========================================================================
    // === Description
    // ==========================================================================

    public actionSetDescription = (newDescription: string) => {
        this.targetGroupData.description = newDescription;
        this.makeCallback();
    }

    // ==========================================================================
    // === Nix Option
    // ==========================================================================

    public actionSetNixOptionId = (nixOptionId: number) => {
        if (this.targetGroupData.state_is_saving) {
            return;
        }
        this.targetGroupData.nix_option_id = nixOptionId;
        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    // ==========================================================================
    // === Source
    // ==========================================================================

    public actionSetSourceId = (sourceId: number) => {
        if (this.targetGroupData.state_is_saving) {
            return;
        }
        this.targetGroupData.source_id = sourceId;
        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    // ==========================================================================
    // === Household option
    // ==========================================================================

    public actionSetHouseholdOptionId = (householdOptionId: number) => {
        if (this.targetGroupData.state_is_saving) {
            return;
        }
        this.targetGroupData.household_option_id = householdOptionId;
        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    // ==========================================================================
    // === Filters
    // ==========================================================================

    public actionSetFiltersSelected = (filterIds: Array<number>) => {

        if (this.baseData === undefined) {
            return;
        }

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        this.targetGroupData.filter_ids_selected = filterIds;

        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    public actionSetFiltersVisible = (filterIds: Array<number>) => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        if (this.baseData === undefined) {
            return;
        }
        this.targetGroupData.filter_ids_visible = filterIds;

        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    // ==========================================================================
    // === Map area
    // ==========================================================================

    public actionAddMapArea = (area: MapAreaCircle|MapAreaPolygon) : void => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        let newId = 1;
        for (const item of this.targetGroupData.map_areas) {
            if (item.id >= newId) {
                newId = item.id + 1;
            }
        }
        let newItem = undefined;
        if (area instanceof MapAreaCircle) {
            newItem = MapAreaCircle.createClone(area);
            newItem.id = newId;
        } else if (area instanceof MapAreaPolygon) {
            newItem = MapAreaPolygon.createClone(area);
            newItem.id = newId;
        }

        if (newItem === undefined) {
            return
        }

        this.targetGroupData.map_areas.push(newItem);

        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    public actionRemoveMapArea = (areaId: number) : void => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        for (let k = 0; k < this.targetGroupData.map_areas.length; k ++) {
            if (this.targetGroupData.map_areas[k].id === areaId) {
                this.targetGroupData.map_areas.splice(k, 1);
                break;
            }
        }

        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    // ==========================================================================
    // === Export fields
    // ==========================================================================

    public actionAddExportField = (exportFieldId: number) => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        let newArray = [...this.targetGroupData.export_field_ids, exportFieldId];
        newArray = UtilArray.createUniqueSortedIntArray(newArray);
        this.targetGroupData.export_field_ids = newArray;
        this.makeCallback();
    }

    public actionRemoveExportField = (exportFieldId: number) => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        const newArray = UtilArray.removeArrayFromArray([exportFieldId], this.targetGroupData.export_field_ids);
        this.targetGroupData.export_field_ids = newArray;
        this.makeCallback();
    }

    // ==========================================================================
    // === Stopfiles
    // ==========================================================================

    public actionAddStopfiles = (stopFileIds: Array<number>) => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        let newArray = [...this.targetGroupData.stopfile_ids, ...stopFileIds];
        newArray = UtilArray.createUniqueSortedIntArray(newArray);
        this.targetGroupData.stopfile_ids = newArray;
        
        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    public actionRemoveStopfiles = (stopFileIds: Array<number>) => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        const newArray = UtilArray.removeArrayFromArray(stopFileIds, this.targetGroupData.stopfile_ids);
        this.targetGroupData.stopfile_ids = newArray;
        
        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    public actionSetStopfileDays = (days: number) => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        this.targetGroupData.stopfile_days = days;
        
        this.tryUpdateAllCounts();
        this.makeCallback();
    }

    // ==========================================================================
    // === Save
    // ==========================================================================

    public actionSave = () => {

        if (this.targetGroupData.state_is_saving) {
            return;
        }

        const tg = this.targetGroupData;

        const inputData = {
            id: this.apiTargetGroup.id,
            name: tg.name,
            description: tg.description,
            sort_order: tg.sort_order,
            source_id: tg.source_id,
            nix_option_id: tg.nix_option_id,
            filter_ids: [...tg.filter_ids_selected],
            map_areas: tg.map_areas,
            export_field_ids: [...tg.export_field_ids],
            stopfile_ids: [...tg.stopfile_ids],
            stopfile_days: tg.stopfile_days,
            household_option_id: tg.household_option_id,
        }
        api_update_target_group(inputData)
            .then((resp) => {
                this.targetGroupData = TargetGroupEditData.createFromApiObj(resp, this.baseData, this.apiObjStopfiles, this.userMy);
                this.apiTargetGroup = resp;
                this.tryUpdateAllCounts();
                this.trySaveCount();
                this.makeCallback();
            })
            .catch((err) => {
                console.error("Could not save target group.");
                this.targetGroupData.state_is_saving = false;
                this.makeCallback();
            });
        
        this.targetGroupData.state_is_saving = true;
        this.makeCallback();
    }

    // ==========================================================================
    // === Counts
    // ==========================================================================

    private tryUpdateAllCounts = () => {

        const tempMapAreas = [];
        for (const item of this.targetGroupData.map_areas) {
            if (item instanceof MapAreaCircle) {
                tempMapAreas.push(MapAreaCircle.createClone(item));
            } else if (item instanceof MapAreaPolygon) {
                tempMapAreas.push(MapAreaPolygon.createClone(item));
            } else {
                throw new Error("Unknown type");
            }
        }

        const newParamsFilter = {
            nix_option_id: this.targetGroupData.nix_option_id,
            source_id: this.targetGroupData.source_id,
            filter_ids_selected: [...this.targetGroupData.filter_ids_selected],
            filter_ids_to_check: [...this.targetGroupData.filter_ids_visible],
            map_areas: tempMapAreas,
        }
        this.countFilterManager.actionNewParams(newParamsFilter);

        const newParamsOrder = {
            nix_option_id: this.targetGroupData.nix_option_id,
            source_id: this.targetGroupData.source_id,
            filter_ids: [...this.targetGroupData.filter_ids_selected],
            map_areas: tempMapAreas,
            stopfile_ids: [...this.targetGroupData.stopfile_ids],
            household_option_id: this.targetGroupData.household_option_id
        };

        this.countOrderManager.actionNewParams(newParamsOrder);
    }

    private trySaveCount = () => {
        if (
            this.targetGroupData.isDataEqual(this.apiTargetGroup) // data in sync.
            && this.targetGroupData.order_count !== undefined
            && this.targetGroupData.order_count.state === CountOrderData.STATE_READY
            && this.targetGroupData.order_count.api_data !== undefined
        ) {
            const count = this.targetGroupData.order_count.api_data.counts.base;
            api_update_target_group_count(this.apiTargetGroup.id, count);  // Just push data.
        }
    }

    // ==========================================================================
    // === Callback
    // ==========================================================================

    private makeCallback = () : void => {
        this.targetGroupData.has_unsaved_changes = !this.targetGroupData.isDataEqual(this.apiTargetGroup);
        const orderDataClone = TargetGroupEditData.createClone(this.targetGroupData);
        if (this.callbackNewData !== undefined) {
            this.callbackNewData(orderDataClone);
        }
    }
}
