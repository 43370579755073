import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfile } from '../../object/stopfile/ApiObjStopfile';

export const api_get_stopfiles = () : Promise<Array<ApiObjStopfile>> => {
    return new Promise<Array<ApiObjStopfile>> ((resolve, reject) => {
        const url = API_URL+'/stopfile';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            const ret = [];
            for (const item of resp.data) {
                ret.push(new ApiObjStopfile(item));
            }
            resolve(ret);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}