
interface InParams {
    data: any,
    nixOptionId: number,
    sourceId: number,
    filterIds: Array<number>,
    mapAreaIds: Array<number>,
    stopfileIds: Array<number>,
    householdOptionId: number,
}

export class ApiObjCountOrder {

    public readonly params: {
        nix_option_id: number,
        source_id: number,
        filter_ids: Array<number>,
        map_area_ids: Array<number>,
        stopfile_ids: Array<number>,
        household_option_id: number,
    };
    public readonly counts: {
        base: number,
        removed_by_stopfiles: number,
        removed_by_households: number,
        final_count: number
    };

    constructor(inParams: InParams) {
        this.params = {
            nix_option_id: inParams.nixOptionId,
            source_id: inParams.sourceId,
            filter_ids: inParams.filterIds,
            map_area_ids: inParams.mapAreaIds,
            stopfile_ids: inParams.stopfileIds,
            household_option_id: inParams.householdOptionId
        }
        const data = inParams.data;
        this.counts = {
            base: parseInt(data.counts.base),
            removed_by_stopfiles: parseInt(data.counts.removed_by_stopfiles),
            removed_by_households: parseInt(data.counts.removed_by_households),
            final_count: parseInt(data.counts.final_count),
        };
    }
}