import { Box, Button, Paper, TextField, } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminNixEntry } from '../../../api/object/admin/ApiObjAdminNixEntry';
import { api_admin_get_nix_entry } from '../../../api/endpoint/admin/nix/api_admin_get_nix_entry';
import { api_admin_get_number_blacklist_entry } from '../../../api/endpoint/admin/number_blacklist/api_admin_get_number_blacklist_entry';
import { api_admin_add_number_blacklist_entry } from '../../../api/endpoint/admin/number_blacklist/api_admin_add_number_blacklist_entry';
import { api_admin_delete_number_blacklist_entry } from '../../../api/endpoint/admin/number_blacklist/api_admin_delete_number_blacklist_entry';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
}

export const AdminBlockedNumbersPage: FunctionComponent <Props> = ({
    baseData,
    userData,
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [currentNumber, setCurrentNumber] = useState <string> ('');
    const [isLoading, setIsLoading] = useState <boolean> (false);
    const [hasResult, setHasResult] = useState <boolean> (false);

    const [resultNix, setResultNix] = useState <undefined|ApiObjAdminNixEntry> (undefined);
    const [resultBlacklist, setResultBlacklist] = useState <undefined|string> ('');

    const [errorMsg, setErrorMsg] = useState <string> ('');

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSearch = () => {

        if (currentNumber.length < 5 || currentNumber.length > 15) {
            return;
        }

        setResultNix(undefined);
        setResultBlacklist(undefined);
        setIsLoading(true);
        setErrorMsg('');

        const promise1 = api_admin_get_nix_entry(currentNumber);
        const promise2 = api_admin_get_number_blacklist_entry(currentNumber);
        
        Promise.all([promise1, promise2])
            .then((result) => {
                setResultNix(result[0]);
                setResultBlacklist(result[1]);
                setHasResult(true);
                setIsLoading(false);

            }).catch((err) => {
                console.error("Error fetching nix or blacklist");
                console.error(err);
                setIsLoading(false);
                setErrorMsg("Error fetching nix or blacklist");
            })
    }

    const actionAddCurrentNumber = () => {

        setIsLoading(true);

        api_admin_add_number_blacklist_entry(currentNumber)
            .then((result) => {
                actionSearch();

            }).catch((err) => {
                console.error("Error when adding number to blacklist.");
                console.error(err);
                setIsLoading(false);
                setErrorMsg("Error when adding number to blacklist.");
            });
    }

    const actionDeleteCurrentNumber = () => {
        setIsLoading(true);

        api_admin_delete_number_blacklist_entry(currentNumber)
            .then((result) => {
                actionSearch();

            }).catch((err) => {
                console.error("Error when deleting number from blacklist.");
                console.error(err);
                setIsLoading(false);
                setErrorMsg("Error when deleting number from blacklist.");
            });
    }

    const actionChangeCurrentNumber = (newNumber: string) => {

        const allowedChars = ['0','1','2','3','4','5','6','7','8','9'];
        let cleanString = '';
        for (let k = 0; k < newNumber.length; k ++) {
            const tempChar = newNumber.charAt(k);
            if (allowedChars.includes(tempChar)) {
                cleanString = cleanString+''+tempChar;
            }
        }
        setCurrentNumber(cleanString);
        setHasResult(false);
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderResultNix = () : JSX.Element => {

        let text = '';

        if (isLoading) {
            text = '...';
        } else if (!hasResult) {
            text = '';
        } else if (resultNix === undefined) {
            text = 'Ingen träff';
        } else {
            text = resultNix.phone_number+' hittades. Senast uppdaterad: '+resultNix.updated_at;
        }

        return (
            <Box marginTop={'20px'} width={'600px'} display={'flex'} flexDirection={'column'}>
                <Box>
                    Nix:
                </Box>
                <Box>
                    {text}
                </Box>
            </Box>
        );
    }

    const renderResultBlacklist = () : undefined|JSX.Element => {

        const elements = [];
        
        if (isLoading) {
            elements.push(<Box key={2}>...</Box>);

        } else if (!hasResult) {
            // Just empty.

        } else if (resultBlacklist === undefined) {
            elements.push(<Box key={3}>Ingen träff</Box>);
            elements.push(
                <Button
                    key={4}
                    size={'medium'}
                    variant='contained'
                    color='secondary'
                    style={{width: '300px'}}
                    onClick={actionAddCurrentNumber}>
                        Lägg till på blacklist
                </Button>
            );

        } else {
            elements.push(<Box key={5}>{resultBlacklist} hittades.</Box>);
            elements.push(
                <Button
                    key={6}
                    size={'medium'}
                    variant='outlined'
                    color='secondary'
                    style={{width: '300px'}}
                    onClick={actionDeleteCurrentNumber}>
                        Ta bort från blacklist
                </Button>
            );
        }

        return (
            <Box marginTop={'20px'} width={'600px'} display={'flex'} flexDirection={'column'}>
                <Box>Blacklist:</Box>
                <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
                    {elements}
                </Box>
            </Box>
        );
    }

    const tryRenderError = () : undefined|JSX.Element => {
        if (errorMsg.length === 0) {
            return undefined;
        }
        return (
            <Box marginTop={'20px'}
                style={{color: '#EE0000'}}>
                    {errorMsg}
            </Box>
        );
    }

    return (
        <Box>
            <Paper>
                <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
                    <Box paddingTop={'10px'} paddingBottom={'10px'} display={'flex'} flexDirection={'row'} gap={'12px'}>
                        <TextField
                            variant="outlined"
                            size={'medium'}
                            label={'Sök nummer'}
                            disabled={isLoading}
                            value={currentNumber}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    actionSearch();
                                }
                            }}
                            onChange={(e) => {
                                actionChangeCurrentNumber(e.target.value);
                            }}
                            style = {{width: '300px'}}
                        />
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={actionSearch}
                            disabled={isLoading || currentNumber.length < 5 || currentNumber.length > 15}>
                            Sök
                        </Button>
                    </Box>
                    {tryRenderError()}
                    {renderResultNix()}
                    {renderResultBlacklist()}
                </Box>
            </Paper>
        </Box>
    );
}
