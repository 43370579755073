import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_update_target_group_count = (id: number, count: number) : Promise<boolean> => {
    return new Promise<boolean> ((resolve, reject) => {
        const url = API_URL+'/target_group/'+id+'/count';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        const payload = {
            count: count
        }

        Axios.post(
            url,
            payload,
            config
        )
        .then((resp) => {
            return resolve(true);

        }).catch((err) => {
            console.error(url+" ERROR: " + err);
            return reject(err);
        });
    });
}
