export const util_strings_format_with_spaces = (num: number): string => {

    if (isNaN(num)) {
      return '';
    }
    const roundedNum = Math.round(num * 100) / 100;
    const parts = roundedNum.toString().split('.');
  
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (parts[1]) {
      parts[1] = parts[1].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  
    return parts.join('.');
};

export const util_strings_format_price_w_decimals = (num: number): string => {
    if (isNaN(num)) {
        return '';
    }
    const roundedNum = Math.round(num * 100) / 100;
    const parts = roundedNum.toString().split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (parts[1]) {
        parts[1] = parts[1].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    if (parts[1] === undefined || parts[1] === null || parts[1].length == 0) {
        parts[1] = '00';
    } else if (parts[1].length == 1) {
        parts[1] = parts[1]+'0';
    } else if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
    }

    return parts.join(',');
};

export const util_strings_random = (length: number) : string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i ++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}