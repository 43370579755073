import { ApiObjBaseData } from "../../api/object/base_data/ApiObjBaseData";
import { ApiObjUserMy } from "../../api/object/user/ApiObjUserMy";
import { UtilArray } from "../../util/code/UtilArray";

export const clean_filters = (filterIds: Array<number>, baseData: ApiObjBaseData, userMy: ApiObjUserMy|undefined) : Array<number> => {

    const cleanFilterIds = [];
    for (const filterId of filterIds) {
        const tempFilter = baseData.filter_map.get(filterId);
        if (tempFilter !== undefined && tempFilter.is_selectable) {
            if (tempFilter.is_premium) {
                if (userMy !== undefined && userMy.isPremiumFilterEnabled(filterId)) {
                    cleanFilterIds.push(filterId);
                }

            } else {
                cleanFilterIds.push(filterId);
            }
        }
    }
    return UtilArray.createUniqueSortedIntArray(cleanFilterIds);
}