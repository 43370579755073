import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjTargetGroup } from '../../object/target_group/ApiObjTargetGroup';
import { MapAreaCircle } from '../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../data_layer/map/MapAreaPolygon';
import { UtilArray } from '../../../util/code/UtilArray';

export type ApiCreateTargetGroupData = {
    name: string,
    description?: string,
    sort_order?: number,
    nix_option_id?: number,
    source_id?: number,
    filter_ids?: Array<number>,
    map_areas?: Array<MapAreaCircle|MapAreaPolygon>;
    export_field_ids?: Array<number>,
    stopfile_ids?: Array<number>,
    stopfile_days?: number,
    household_option_id?: number
    facebook_target_group?: {
        is_enabled: boolean,
        is_auto_update: boolean,
        ad_account_ids: Array<string>
    }
}

export const api_create_target_group = (data: ApiCreateTargetGroupData) : Promise<ApiObjTargetGroup> => {
    return new Promise<ApiObjTargetGroup> ((resolve, reject) => {
        const url = API_URL+'/target_group/create';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        Axios.post(
            url,
            createBodyData(data),
            config
        )
        .then((resp) => {
            return resolve(new ApiObjTargetGroup(resp.data));

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}

const createBodyData = (data: ApiCreateTargetGroupData) => {

    let filterIdsFinal: undefined|Array<number> = undefined;
    if (data.filter_ids !== undefined) {
        if (filterIdsFinal === undefined) {
            filterIdsFinal = [...data.filter_ids];
        } else {
            filterIdsFinal = [...filterIdsFinal, ...data.filter_ids];
        }
    }
    
    let mapAreas: undefined|Array<any> = [];

    if (data.map_areas !== undefined) {
        mapAreas = [];
        for (const item of data.map_areas) {
            if (item instanceof MapAreaCircle) {
                mapAreas.push({
                    type: 'circle',
                    center_lat: item.centerPoint.latitude,
                    center_long: item.centerPoint.longitude,
                    radius: item.radius,
                });

            } else if (item instanceof MapAreaPolygon) {
                const tempPoints = [];
                for (const p of item.points) {
                    tempPoints.push({
                        lat: p.latitude,
                        long: p.longitude
                    })
                }
                mapAreas.push({
                    type: 'polygon',
                    points: tempPoints
                });
            } else {
                throw new Error("Unexpected map area type");
            }
        }
    }

    let facebookTargetGroup = undefined;
    if (data.facebook_target_group !== undefined) {
        facebookTargetGroup = {
            is_enabled: data.facebook_target_group.is_enabled ? 1 : 0,
            is_auto_update: data.facebook_target_group.is_auto_update ? 1 : 0,
            ad_account_ids: data.facebook_target_group.ad_account_ids
        }
    }
    
    return {
        name: data.name,
        description: data.description,
        sort_order: data.sort_order,
        nix_option_id: data.nix_option_id !== undefined ? data.nix_option_id : undefined,
        source_id: data.source_id,
        filter_ids: data.filter_ids !== undefined ? UtilArray.createUniqueSortedIntArray(data.filter_ids) : undefined,
        map_areas: mapAreas,
        export_field_ids: data.export_field_ids !== undefined ? UtilArray.createUniqueSortedIntArray(data.export_field_ids) : undefined,
        stopfile_ids: data.stopfile_ids !== undefined ? UtilArray.createUniqueSortedIntArray(data.stopfile_ids) : undefined,
        stopfile_days: data.stopfile_days,
        household_option_id: data.household_option_id,
        facebook_target_group: facebookTargetGroup
    }
}
