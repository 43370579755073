import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_order_post_step_2 = (populateFileId: number, dataOptionIds: Array<number>, cleanLowIncome: number, cleanNix: number, cleanHlr: number) : Promise<ApiObjPopulateFile> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/populate_file/'+populateFileId+'/order_step_2';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        const body = {
            data_option_ids: dataOptionIds,
            clean_low_income: cleanLowIncome,
            clean_nix: cleanNix,
            clean_hlr: cleanHlr 
        }
        Axios.post(
            url,
            body,
            config
        )
        .then((resp) => {
            resolve(new ApiObjPopulateFile(resp.data));
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}