import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjUserMy } from '../../object/user/ApiObjUserMy';

export interface GetMyUserResponse {
    logged_in: boolean,
    user_data: undefined|ApiObjUserMy
}

export const api_get_my_user = () : Promise<GetMyUserResponse> => {

    return new Promise <GetMyUserResponse> ((resolve, reject) => {

        const url = API_URL+'/my_user';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {

            if (resp.data.logged_in == 0) {
                return resolve({
                    logged_in: false,
                    user_data: undefined
                });
            } else {
                return resolve({
                    logged_in: true,
                    user_data: new ApiObjUserMy(resp.data.user_data)
                });
            }
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}