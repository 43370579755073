import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';
import { ApiObjAdminBaseData } from '../../../object/admin/ApiObjAdminBaseData';

export const api_admin_get_base_data = () : Promise<ApiObjAdminBaseData> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/admin/base_data';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            resolve(new ApiObjAdminBaseData(resp.data));
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}