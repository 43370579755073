import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { MapAreaCircle } from '../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../data_layer/map/MapAreaPolygon';
import { UtilArray } from '../../../util/code/UtilArray';

export type LinkInputData = {
    nix_option_id?: number,
    source_id?: number,
    filter_ids?: Array<number>,
    map_areas?: Array<MapAreaCircle|MapAreaPolygon>;
    export_field_ids?: Array<number>,
    stopfile_ids?: Array<number>,
    stopfile_days?: number,
    household_option_id?: number,
}

export const api_create_link_order_data = (data: LinkInputData) : Promise<string> => {
    return new Promise<string> ((resolve, reject) => {
        const url = API_URL+'/link/order_data';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        Axios.post(
            url,
            createBodyData(data),
            config
        )
        .then((resp) => {
            return resolve(resp.data.code);

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}

const createBodyData = (data: LinkInputData) => {

    let mapAreas: undefined|Array<any> = [];

    if (data.map_areas !== undefined) {
        mapAreas = [];
        for (const item of data.map_areas) {
            if (item instanceof MapAreaCircle) {
                mapAreas.push({
                    type: 'circle',
                    center_lat: item.centerPoint.latitude,
                    center_long: item.centerPoint.longitude,
                    radius: item.radius,
                });

            } else if (item instanceof MapAreaPolygon) {
                const tempPoints = [];
                for (const p of item.points) {
                    tempPoints.push({
                        lat: p.latitude,
                        long: p.longitude
                    })
                }
                mapAreas.push({
                    type: 'polygon',
                    points: tempPoints
                });
            } else {
                throw new Error("Unexpected map area type");
            }
        }
    }
    
    return {
        nix_option_id: data.nix_option_id,
        source_id: data.source_id,
        filter_ids: data.filter_ids !== undefined ? UtilArray.createUniqueSortedIntArray(data.filter_ids) : undefined,
        map_areas: mapAreas,
        export_field_ids: data.export_field_ids !== undefined ? UtilArray.createUniqueSortedIntArray(data.export_field_ids) : undefined,
        stopfile_ids: data.stopfile_ids !== undefined ? UtilArray.createUniqueSortedIntArray(data.stopfile_ids) : undefined,
        stopfile_days: data.stopfile_days,
        household_option_id: data.household_option_id
    }
}