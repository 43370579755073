import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';

export const api_admin_add_number_blacklist_entry = (number: string) : Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/admin/number_blacklist?phone_number='+number;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.post(
            url,
            config
        )
        .then((resp) => {
            if (resp.data.success !== true) {
                throw new Error("Unexpected response.");
            }
            return resolve(true);

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}