import { ApiObjUserMy } from "../../api/object/user/ApiObjUserMy";

export const clean_source = (sourceId: number, userMy: undefined|ApiObjUserMy) : number => {
    if (sourceId === 0 || userMy === undefined) {
        return 0;
    }
    if (userMy.getSource(sourceId) === undefined) {
        return 0;
    }
    return sourceId;
}
