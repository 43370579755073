import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_delete_api_key = (apiKeyId: number) : Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/api_key/'+apiKeyId;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.delete(
            url,
            config
        )
        .then((resp) => {
            return resolve(true);
            
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}