import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_logout = () : Promise<{success: boolean}> => {

    return new Promise <{success: boolean}> ((resolve, reject) => {

        const url = API_URL+'/user/logout';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.post(
            url,
            {},
            config
        )
        .then((resp) => {
            resolve({success: true});
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}