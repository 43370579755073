import { api_get_filter_count_order } from '../../../api/endpoint/filter/api_get_filter_count_order';
import { ApiObjCountOrder } from '../../../api/object/filter/ApiObjCountOrder';
import { MapAreaCircle } from '../../map/MapAreaCircle';
import { MapAreaPolygon } from '../../map/MapAreaPolygon';
import { CountOrderData } from './CountOrderData';

export type CountOrderParams = {
    nix_option_id: number,
    source_id: number,
    filter_ids: Array<number>,
    map_areas: Array<MapAreaCircle|MapAreaPolygon>,
    stopfile_ids: Array<number>,
    household_option_id: number
}

/**
 * Manages count calls (order). An important reason is preventing spamming when
 * the user changes the order quickly.
 */
export class CountOrderManager {
    
    private callbackNewData: (newData: undefined|CountOrderData) => void;
    private lastParams: undefined|CountOrderParams;
    private countOrderData: undefined|CountOrderData = undefined;
    private requestCounter = 0;              // Used to determine if a scheduled request is the latest.
    private timestampLastParamChange = 0;    // Used to determine whether to make request immediately.

    // ================================================================
    // === Create
    // ================================================================

    constructor(callbackNewData: (newData: undefined|CountOrderData) => void) {
        this.callbackNewData = callbackNewData;
    }

    // ================================================================
    // === Actions
    // ================================================================

    public actionNewParams = (params: CountOrderParams) => {
        this.lastParams = params;
        this.tryUpdateOrderCountResult();
        this.tryScheduleOrderCount();
    }

    private makeCallback = () => {
        if (this.countOrderData === undefined) {
            this.callbackNewData(undefined);
        } else {
            const newData = CountOrderData.createClone(this.countOrderData);
            this.callbackNewData(newData);
        }
    }

    // ================================================================
    // === Helpers
    // ================================================================

    private tryUpdateOrderCountResult = () : void => {

        if (this.lastParams === undefined) {
            return;
        }

        const params = {
            nixOptionId: this.lastParams.nix_option_id,
            sourceId: this.lastParams.source_id,
            filterIds: this.lastParams.filter_ids,
            mapAreas: this.lastParams.map_areas,
            stopfileIds: this.lastParams.stopfile_ids,
            householdOptionId: this.lastParams.household_option_id,
        }
        
        if (this.countOrderData === undefined) {
            if (params.nixOptionId != 0) {
                this.countOrderData = CountOrderData.createNew(params);
            }

        } else if (this.countOrderData !== undefined) {
            if (params.nixOptionId == 0) {
                this.countOrderData = undefined;
            } else if (!this.countOrderData.isParamsEqual(params)) {
                this.countOrderData = CountOrderData.createNew(params);
            }
        }
        this.makeCallback();
    }

    private tryScheduleOrderCount = () : void => {

        if (this.countOrderData === undefined) {
            return;
        }
        if (this.countOrderData.state != CountOrderData.STATE_WAITING) {
            return;
        }

        this.requestCounter ++;
        const counterValue = this.requestCounter;

        const now = Date.now();
        const millisSinceLastChange = now - this.timestampLastParamChange;
        this.timestampLastParamChange = now;

        if (millisSinceLastChange > 5000) {
            // A long time (5 s) since last count. Run immediately.
            this.tryFetchOrderCount(counterValue);
        } else {
            // Not a long time ago. Delay count, user might update params soon.
            setTimeout(() => {this.tryFetchOrderCount(counterValue)}, 1500);
        }
    }

    private tryFetchOrderCount = (counterValue: number) : void => {

        if (counterValue != this.requestCounter) {
            return; // Another fetch is scheduled after this. Let that run instead.
        }
        if (this.countOrderData === undefined) {
            return;
        }
        if (this.countOrderData.state !== CountOrderData.STATE_WAITING) {
            return;
        }
        if (this.lastParams === undefined) {
            return;
        }

        const params = {
            nixOptionId: this.lastParams.nix_option_id,
            sourceId: this.lastParams.source_id,
            filterIds: [...this.lastParams.filter_ids],
            mapAreas: this.lastParams.map_areas,
            stopfileIds: [...this.lastParams.stopfile_ids],
            householdOptionId: this.lastParams.household_option_id,
        }

        api_get_filter_count_order(params)
            .then((result) => {
                this.handleResultOrderCount(result);

            }).catch((err) => {
                // TODO ERIK hantera fel vid count. Sätt loading items till error kanske?? Nytt state.
                console.error("Could not fetch filter count");
                throw err;
            });
    }

    private handleResultOrderCount = (result: ApiObjCountOrder) : void => {

        if (this.countOrderData === undefined) {
            return;
        }
        if (this.lastParams === undefined) {
            return;
        }

        const params = {
            nixOptionId: this.lastParams.nix_option_id,
            sourceId: this.lastParams.source_id,
            filterIds: this.lastParams.filter_ids,
            mapAreas: this.lastParams.map_areas,
            stopfileIds: this.lastParams.stopfile_ids,
            householdOptionId: this.lastParams.household_option_id
        }
        if (!this.countOrderData.isParamsEqual(params)) {
            return; // Params not equal to current, discard result.
        }
        this.countOrderData.actionReady(result);
        this.makeCallback();
    }

}