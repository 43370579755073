import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export const api_admin_user_save_sources = (userId: number, sourceIds: Array<number>) : Promise<Array<number>> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/admin/user/'+userId+'/source';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.put(
            url,
            sourceIds,
            config
        )
        .then((resp) => {
            const result = [];
            for (const item of resp.data) {
                result.push(parseInt(item));
            }
            resolve(result);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}