import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfile } from '../../object/stopfile/ApiObjStopfile';

/**
 * @param {number} stopfileId 
 * @param {Array<{id: number, type: number}>} columns 
 * @returns 
 */
export const api_process_stopfile = (stopfileId: number, columnTypes: Array<number>) : Promise<ApiObjStopfile> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/stopfile/'+stopfileId+'/process';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true
        }
        const data = {
            column_types: columnTypes,
        }
        Axios.post(
            url,
            data,
            config
        )
        .then((resp) => {
            resolve(new ApiObjStopfile(resp.data));
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}