import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export interface AdminUserDetailsParams {
    is_admin?: boolean
    is_active?: boolean
    first_name?: string
    last_name?: string
    email?: string
    company_name?: string
    company_number?: string
    address?: string
    zipcode?: string
    city?: string
    phone_number?: string
    api_monthly_quota: number
    has_invoice?: boolean
    subscription_type?: number
    subscription_price?: number
    max_leads?: number
    total_leads?: number
    populate_file_subscription_type?: number
    populate_file_subscription_price?: number
    populate_file_subscription_spending?: number
    populate_file_subscription_spending_max?: number
}

export const api_admin_user_save_details = (userId: number, data: AdminUserDetailsParams) : Promise<boolean> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/admin/user/'+userId+'/details';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.patch(
            url,
            data,
            config
        )
        .then((resp) => {
            resolve(true);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}