import { Box, Modal, Paper, } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../data_layer/user/UserData';
import { ApiObjExport } from '../../api/object/export/ApiObjExport';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FilterTreeMini } from '../../data_layer/filtertree/FilterTreeMini';
import { FilterTreeMiniItem } from '../../data_layer/filtertree/FilterTreeMiniItem';

type Props = {
    userData: UserData,
    baseData: ApiObjBaseData,
    exportObj: ApiObjExport
    onClose: () => void
}

export const ExportInfoModal: FunctionComponent<Props> = ({
    userData,
    baseData,
    exportObj,
    onClose
}) => {

    // ===========================================================
    // === State
    // ===========================================================

    const [hoverItem, setHoverItem] = useState <string> ('');
    const [expandedItem, setExpandedItem] = useState <string> ('');

    // ===========================================================
    // === Render Sections
    // ===========================================================

    const styleInfoItem = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
    } as React.CSSProperties;

    const helperGetStyleForItem = (itemName: string) : React.CSSProperties => {
        if (itemName === hoverItem) {
            return {
                ...styleInfoItem,
                backgroundColor: '#f5f5f5'
            }
        }
        return styleInfoItem;
    }

    const renderTitle = () => {
        return (
            <Box
                onMouseEnter={() => { setHoverItem('title') }}
                onMouseLeave={() => { setHoverItem('') }}
                style={helperGetStyleForItem('title')}>
                
                <Box>Namn:</Box>
                <Box>{exportObj.name}</Box>
            </Box>
        );
    }

    const renderNixOption = () => {
        
        const nixOptionId = exportObj.params.nix_option_id;
        let nixOption = baseData.getNixOption(nixOptionId);
        if (nixOption === undefined && userData.isLoggedIn() && userData.api_data !== undefined) {
            nixOption = userData.api_data.getNixOption(nixOptionId);
        }

        let nixOptionName = 'Not found';
        if (nixOption !== undefined) {
            nixOptionName = nixOption.name;
        }

        return (
            <Box
                onMouseEnter={() => { setHoverItem('nix_option') }}
                onMouseLeave={() => { setHoverItem('') }}
                style={helperGetStyleForItem('nix_option')}>

                <Box>
                    Nixalternativ:
                </Box>
                <Box>
                    {nixOptionName}
                </Box>
            </Box>
        );
    }

    const renderFilters = () => {

        let expandedContainerElement = undefined;
        if (expandedItem === 'filters') {

            const tempElements : Array<JSX.Element> = [];
            const filterTreeMini = new FilterTreeMini(
                exportObj.params.filter_ids,
                baseData
            );


            for (const treeItem of filterTreeMini.root_items) {
                renderItemRecursive(treeItem, tempElements, 0);
            }

            expandedContainerElement = (
                <Box display={'flex'} flexDirection={'column'} padding={'14px'}>
                    {tempElements}
                </Box>
            );
        }

        const filterCount = exportObj.params.filter_ids.length;

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <Box
                    onMouseEnter={() => { setHoverItem('filters') }}
                    onMouseLeave={() => { setHoverItem('') }}
                    onClick={() => {
                        if (expandedItem === 'filters') {
                            setExpandedItem('');
                        } else {
                            setExpandedItem('filters');
                        }
                    }}
                    style={helperGetStyleForItem('filters')}>

                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>Filter:</Box>
                        <Box marginLeft={'4px'}><ExpandMoreIcon /></Box>
                    </Box>
                    <Box>
                        {filterCount + ' filter'}
                    </Box>
                </Box>
                {expandedContainerElement}
            </Box>
        );
    }

    const renderItemRecursive = (treeItem: FilterTreeMiniItem, elements: Array<JSX.Element>, level: number) => {
        const indent = level * 20 + 'px';
        elements.push(
            <Box 
                key={treeItem.filter.id}
                paddingLeft={indent}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
            >
                <Box>{treeItem.filter.name}</Box>
            </Box>
        );
        for (const item of treeItem.children) {
            renderItemRecursive(item, elements, level + 1);
        }
    }

    const renderMap = () => {

        const mapAreaCount = exportObj.params.map_areas.length;

        return (
            <Box
                onMouseEnter={() => { setHoverItem('map') }}
                onMouseLeave={() => { setHoverItem('') }}
                style={helperGetStyleForItem('map')}>

                <Box>
                    Karta:
                </Box>
                <Box>
                    {mapAreaCount + ' områden'}
                </Box>
            </Box>
        );
    }

    const renderExportFields = () => {

        let expandedContainerElement = undefined;
        if (expandedItem === 'export_fields') {

            const tempElements : Array<JSX.Element> = [];
            
            for (const fieldId of exportObj.params.export_field_ids) {

                let name = '';
                const tempField = baseData.getExportField(fieldId);
                if (tempField !== undefined) {
                    name = tempField.name;
                }

                tempElements.push(
                    <Box 
                        key={fieldId}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Box>{name}</Box>
                    </Box>
                );
            }

            expandedContainerElement = (
                <Box display={'flex'} flexDirection={'column'} padding={'14px'}>
                    {tempElements}
                </Box>
            );
        }

        const fieldCount = exportObj.params.export_field_ids.length;

        return (
            <Box display={'flex'} flexDirection={'column'}>
                
                <Box
                    onMouseEnter={() => { setHoverItem('export_fields') }}
                    onMouseLeave={() => { setHoverItem('') }}
                    onClick={() => {
                        if (expandedItem === 'export_fields') {
                            setExpandedItem('');
                        } else {
                            setExpandedItem('export_fields');
                        }
                    }}
                    style={helperGetStyleForItem('export_fields')}>

                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>Leveransfält:</Box>
                        <Box marginLeft={'4px'}><ExpandMoreIcon /></Box>
                    </Box>
                    <Box>
                        {fieldCount +' fält'}
                    </Box>
                </Box>
                {expandedContainerElement}
            </Box>
        );
    }
    
    const renderStopfiles = () => {

        const stopfileCount = exportObj.params.stopfile_ids.length;

        return (
            <Box
                onMouseEnter={() => { setHoverItem('stopfiles') }}
                onMouseLeave={() => { setHoverItem('') }}
                style={helperGetStyleForItem('stopfiles')}>

                <Box>
                    Stoppfiler:
                </Box>
                <Box>
                    {stopfileCount + ' stoppfiler'}
                </Box>
            </Box>
        );
    }

    const renderHouseholdOption = () => {

        const householdOptionId = exportObj.params.household_option_id;
        let householdOptionName = 'Okänt';
        if (householdOptionId === 0) {
            householdOptionName = 'Ingen';
        } else {
            if (userData.isLoggedIn() && userData.api_data !== undefined ) {
                for (const item of userData.api_data.household_options) {
                    if (item.id === householdOptionId) {
                        householdOptionName = item.name;
                        break;
                    }
                }
            }
        }

        return (
            <Box
                onMouseEnter={() => { setHoverItem('household_option') }}
                onMouseLeave={() => { setHoverItem('') }}
                style={helperGetStyleForItem('household_option')}>

                <Box>
                    Hushållsbegränsning:
                </Box>
                <Box>
                    {householdOptionName}
                </Box>
            </Box>
        );
    }

    // ===========================================================
    // === Render Main
    // ===========================================================

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '528px',
        height: '528px'
    } as React.CSSProperties;

    const styleScroll = {
        overflowY: 'auto'
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={onClose}>

            <Box style={style}>
                <Paper>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        style={styleScroll}
                        width={'500px'}
                        height={'500px'}
                        padding={'14px'}>
                        {renderTitle()}
                        {renderNixOption()}
                        {renderFilters()}
                        {renderMap()}
                        {renderExportFields()}
                        {renderStopfiles()}
                        {renderHouseholdOption()}
                    </Box>
                </Paper>
            </Box>

        </Modal>
    );
}