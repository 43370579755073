import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjApiKey } from '../../object/api_key/ApiObjApiKey';

export const api_create_api_key = () : Promise<ApiObjApiKey> => {
    return new Promise((resolve, reject) => {
        const url = API_URL+'/api_key';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.post(
            url,
            {},
            config
        )
        .then((resp) => {
            const apiObj = new ApiObjApiKey(resp.data);
            return resolve(apiObj);
            
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}