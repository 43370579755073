import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { MapAreaCircle } from '../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../data_layer/map/MapAreaPolygon';
import { UtilArray } from '../../../util/code/UtilArray';
import { ApiObjCountOrder } from '../../object/filter/ApiObjCountOrder';

export interface CountOrderParams {
    nixOptionId: number,
    sourceId: number,
    filterIds: Array<number>,
    mapAreas: Array<MapAreaCircle|MapAreaPolygon>,
    stopfileIds: Array<number>,
    householdOptionId: number
}

export const api_get_filter_count_order = (params: CountOrderParams) : Promise<ApiObjCountOrder> => {

    return new Promise <ApiObjCountOrder> ((resolve, reject) => {

        let paramMapAreaIds : Array<number> = [];
        for (const item of params.mapAreas) {
            paramMapAreaIds.push(item.id);
        }
        paramMapAreaIds = UtilArray.createUniqueSortedIntArray(paramMapAreaIds);

        params.filterIds = [...params.filterIds];
        params.stopfileIds = [...params.stopfileIds];

        const url = API_URL+'/data/count-order';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        const payloadMapAreas = [];
        for (const mapArea of params.mapAreas) {
            if (mapArea instanceof MapAreaCircle) {
                payloadMapAreas.push({
                    type: 'circle',
                    center_lat: mapArea.centerPoint.latitude,
                    center_long: mapArea.centerPoint.longitude,
                    radius: mapArea.radius
                });

            } else if (mapArea instanceof MapAreaPolygon) {
                const tempPoints = [];
                for (const point of mapArea.points) {
                    tempPoints.push({
                        lat: point.latitude,
                        long: point.longitude,
                    });
                }
                payloadMapAreas.push({
                    type: 'polygon',
                    points: tempPoints
                });
            } else {
                throw new Error("Unexpected map area type");
            }
        }

        const data = {
            nix_option_id: params.nixOptionId,
            source_id: params.sourceId,
            filter_ids: params.filterIds,
            map_areas: payloadMapAreas,
            stopfile_ids:  params.stopfileIds,
            household_option_id: params.householdOptionId,
        }
        Axios.post(
            url,
            data,
            config
        )
        .then((resp) => {

            const tempParams = {
                data: resp.data,
                nixOptionId: params.nixOptionId,
                sourceId: params.sourceId,
                filterIds: params.stopfileIds,
                mapAreaIds: paramMapAreaIds,
                stopfileIds: params.stopfileIds,
                householdOptionId: params.householdOptionId
            }
            resolve(new ApiObjCountOrder(tempParams));
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}