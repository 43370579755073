import moment from "moment";

export class ApiObjAdminNixEntry {
    
    id: number;
    phone_number: string;
    updated_at: string;

    constructor(data: any) {
        this.id = data.id;
        this.phone_number = data.phone_number;
        this.updated_at = moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss');
    }
}
