import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_delete_target_group = (targetGroupId: number) : Promise<boolean> => {
    return new Promise<boolean> ((resolve, reject) => {
        const url = API_URL+'/target_group/'+targetGroupId;
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }

        Axios.delete(
            url,
            config
        )
        .then((resp) => {
            return resolve(true);

        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            return reject(err);
        });
    });
}
