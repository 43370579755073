import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';
import { ApiObjAdminUserPrice } from '../../../../object/admin/ApiObjAdminUserPrice';

export const api_admin_user_get_prices = (userId: number) : Promise<Array<ApiObjAdminUserPrice>> => {
    return new Promise((resolve, reject) => {
        
        const url = API_URL+'/admin/user/'+userId+'/price';
        const config = {
            'Access-Control-Allow-Origin': API_URL,
            withCredentials: true 
        }
        Axios.get(
            url,
            config
        )
        .then((resp) => {
            const result = [];
            for (const item of resp.data) {
                result.push(new ApiObjAdminUserPrice(item));
            }
            resolve(result);
        }).catch((err) => {
            console.log(url+" ERROR: " + err);
            reject(err);
        });
    });
}