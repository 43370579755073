import { Box, Button } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjTargetGroup } from '../../../api/object/target_group/ApiObjTargetGroup';
import { api_admin_get_users } from '../../../api/endpoint/admin/api_admin_get_users';
import { api_admin_user_get_target_groups } from '../../../api/endpoint/admin/user/target_group/api_admin_user_get_target_groups';
import { api_admin_user_transfer_target_group } from '../../../api/endpoint/admin/user/target_group/api_admin_user_transfer_target_group';
import { ApiObjAdminUserCompact } from '../../../api/object/admin/ApiObjAdminUserCompact';
import { MoveToUserModal } from '../../shared_components/MoveToUserModal';

type Props = {
    userData: UserData,
    otherUserId: number
}

export const AdminUserTabTargetGroups: FunctionComponent <Props> = ({
    userData,
    otherUserId
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [targetGroups, setTargetGroups] = useState <Array<ApiObjTargetGroup>> ([]);
    const [allUsers, setAllUsers] = useState <Array<ApiObjAdminUserCompact>> ([]);
    const [selectedTargetGroupId, setSelectedTargetGroupId] = useState <number> (0);

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {

        fetchAllUsers();
        fetchTargetGroups();

    }, []);

    const fetchAllUsers = () => {
        api_admin_get_users()
            .then((result) => {
                setAllUsers(result);

            }).catch((err) => {
                console.error("Could not fetch users list");
                console.error(err);
            });
    }

    const fetchTargetGroups = () => {
        api_admin_user_get_target_groups(otherUserId)
            .then((result) => {
                setTargetGroups(result);
            }).catch((err) => {
                console.error("Could not fetch target groups list.");
                console.error(err);
            });
    }

    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionMakeMove = (newUserId: number) => {
        api_admin_user_transfer_target_group(otherUserId, selectedTargetGroupId, newUserId)
            .then((resp) => {
                console.log("Flytten lyckades!");
                fetchTargetGroups();

            }).catch((err) => {
                console.error("Move target group failed");
                console.error(err);
            });
    }

    // ======================================================================
    // === Modal move
    // ======================================================================

    const tryRenderModalMove = () : JSX.Element | undefined => {

        if (selectedTargetGroupId === 0) {
            return undefined;
        }

        let targetGroup = undefined;
        for (const item of targetGroups) {
            if (item.id === selectedTargetGroupId) {
                targetGroup = item;
                break;
            }
        }
        if (targetGroup === undefined) {
            return undefined;
        }

        return (
            <MoveToUserModal
                objectTypeName={'Målgrupp'}
                objectDescription={targetGroup.name}
                users={allUsers}
                onClose={() => {setSelectedTargetGroupId(0)}}
                onMakeMove={(newUserId: number) => {actionMakeMove(newUserId)} }
            />
        );
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderList = () : Array<JSX.Element> => {

        const elements = [];

        for (const item of targetGroups) {

            elements.push(
                <Box key={item.id} marginTop={'6px'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Box>{item.name}</Box>
                    <Box>
                        <Button
                            variant='contained'
                            color='secondary'
                            size='small'
                            onClick={() => {setSelectedTargetGroupId(item.id)} }>
                                Flytta
                        </Button>
                    </Box>
                </Box>
            );
            
        }

        return elements;
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            {renderList()}
            {tryRenderModalMove()}
        </Box>
    );
}
