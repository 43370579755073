import React, { FunctionComponent, useState } from 'react';
import { Box, Button, Chip, Typography } from '@mui/material';
import { OrderManager } from '../../../../data_layer/order/OrderManager';
import { ApiObjBaseData } from '../../../../api/object/base_data/ApiObjBaseData';
import { OrderData } from '../../../../data_layer/order/OrderData';
import { MapDrawArea } from '../../../shared_components/MapDrawArea';
import { MapAreaCircle } from '../../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../../data_layer/map/MapAreaPolygon';
import { useNavigate } from 'react-router-dom';
import { FilterTree } from '../../../shared_components/FilterTree';
import { UserData } from '../../../../data_layer/user/UserData';
import { ApiObjPriceObjectType } from '../../../../api/object/price/ApiObjPriceObjectType';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    orderData: OrderData,
    orderManager: OrderManager
}

export const Step2FiltersComp: FunctionComponent<Props> = ({
    baseData,
    userData,
    orderData,
    orderManager
}) => {

    // ===========================================================
    // === State
    // ===========================================================

    const [mapExpanded, setMapExpanded] = useState(false);
    const navigate = useNavigate();

    // ===========================================================
    // === Actions
    // ===========================================================

    const onSelectedFilterIdsChanged = (filterIds: Array<number>) => {
        orderManager.actionSetFiltersSelected(filterIds);
    }

    const onVisibleFilterIdsChanged = (filterIds: Array<number>) => {
        orderManager.actionSetFiltersVisible(filterIds);
    }

    const actionAddArea = (area: MapAreaCircle|MapAreaPolygon) => {
        orderManager.actionAddMapArea(area);
    }

    const actionRemoveArea = (areaId: number) => {
        orderManager.actionRemoveMapArea(areaId);
    }

    const actionToggleMapExpanded = () => {
        setMapExpanded(!mapExpanded);
    }

    const actionNext = () => {
        navigate('/order_leads/3');
    }

    const actionBack = () => {
        navigate('/order_leads/1');
    }

    // ===========================================================
    // === Helpers
    // ===========================================================

    const getNixOptionName = () : string => {
        let nixOption = baseData.getNixOption(orderData.nix_option_id);
        if (nixOption === undefined && userData.isLoggedIn() && userData.api_data !== undefined) {
            nixOption = userData.api_data.getNixOption(orderData.nix_option_id);
        }        
        if (nixOption !== undefined) {
            return nixOption.name;
        }
        return '';
    }

    // ===========================================================
    // === Render
    // ===========================================================

    const renderMapPriceElement = () : JSX.Element|undefined => {

        if (!userData.isLoggedIn() || userData.api_data === undefined) {
            return undefined;
        }

        const priceObject = userData.api_data.getPriceObjectFromType(ApiObjPriceObjectType.ID_LEAD_PRICE_MAP, 0);
        if (priceObject === undefined) {
            return undefined;
        }
        const price = priceObject.getPriceFloat();
        if (price === undefined || price === 0) {
            return undefined;
        }
        
        return (
            <Box marginLeft={'12px'}>
                <Chip label={priceObject.getPriceStringFull()} variant="filled" />
            </Box>
        );
    }

    const renderMap = (): JSX.Element => {
        // Need this to force 2 different instances of map as resize of map seems buggy.
        if (mapExpanded) {
            return (
                <MapDrawArea
                    key={1}
                    editEnabled={orderData.canEditOrder()}
                    expanded={mapExpanded}
                    areas={orderData.map_areas}
                    onAddArea={actionAddArea}
                    onRemoveArea={actionRemoveArea}
                    onToggleMapExpanded={actionToggleMapExpanded}
                />
            );
        } else {
            return (
                <MapDrawArea
                    key={2}
                    editEnabled={false}
                    expanded={mapExpanded}
                    areas={orderData.map_areas}
                    onAddArea={actionAddArea}
                    onRemoveArea={actionRemoveArea}
                    onToggleMapExpanded={actionToggleMapExpanded}
                />
            );
        }
    }

    return (
        <>
        <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="h4">{getNixOptionName()}</Typography>
            <Box display={'flex'} flexDirection={'row'} mb={2} alignItems={'center'}>
                <Typography variant="h5">{'Välj område'}</Typography>
                {renderMapPriceElement()}
            </Box>
            
            {renderMap()}

            <Typography mt={4} variant="h5">{'Specificera din målgrupp'}</Typography>
            <Box mt={2}>
                <FilterTree
                    userData={userData}
                    baseData={baseData}
                    selectedFilterIds={orderData.filter_ids_selected}
                    visibleFilterIds={orderData.filter_ids_visible}
                    filterCounts={orderData.filter_count}
                    nixOptionId={orderData.nix_option_id}
                    onSelectedFilterIdsChanged={onSelectedFilterIdsChanged}
                    onVisibleFilterIdsChanged={onVisibleFilterIdsChanged}
                    disablePrices={false}
                    disabled={!orderData.canEditOrder()}
                />
            </Box>
        </Box>
        
        <Box
            marginTop={4}
            display={'flex'}
            flexDirection={'row'}
            style={{gap: '15px'}}
            justifyContent={'flex-start'}>

            <Button
                onClick={actionBack}
                variant={'outlined'}
                color="secondary"
                size={'large'}>
                    {'Tillbaka'}
            </Button>
            <Button
                onClick={actionNext}
                variant={'contained'}
                color="secondary"
                size={'large'}>
                    {'Nästa'}
            </Button>
        </Box>
        </>
    );
}